@import "@styles/abstracts";

.form-wrapper {
  margin-bottom: 18px;
}
.button {
  @include media-breakpoint-up(xxs) {
    margin-left: auto;
  }
  @include media-breakpoint-down(xxs) {
    width: 100%;
  }
}
.forgot {
  text-align: right;

  &-button {
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    @include transition(color);
    margin-bottom: 12px;

    &:hover {
      color: darken($main-color, 15);
    }
  }
}
