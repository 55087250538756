@import "@styles/abstracts/";

.wrapper {
  position: relative;
  display: flex;
  width: 221px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  opacity: 1;

  @include transition(opacity);

  &:not(.appearance-solid) {
    border: 1px solid;
    @include transition(border-color);
  }
}
.content {
  $paddingX: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: $paddingX;
  padding-left: $paddingX;
}
.appearance {
  &-framed {
    padding: 3px;

    & .content {
      border-radius: 1px;
    }
  }
  &-bordered .content {
    background-color: transparent;
  }
}
.size {
  &-standart {
    height: 40px;
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
  }
  &-thin {
    height: 33px;
    font-size: 14px;
    font-weight: 700;
    line-height: 15px;
  }
}
.theme {
  &-orange {
    &:not(.appearance-bordered) {
      border-color: $color-lighttelegrey;
      color: $color-white;

      &:hover {
        border-color: $secondary-color;
      }

      & .content {
        color: currentColor;
        background: $gradient-orange;
      }
    }
    &.appearance-bordered {
      $color: $secondary-color;

      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15);
        }
      }
    }
  }
  &-gray {
    $color: $color-lighttelegrey;

    &:not(.appearance-bordered) {
      color: $color-white;
      border-color: $color;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $color;
      }
    }
    &.appearance-bordered {
      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15);
        }
      }
    }
  }
  &-dark-gray {
    &:not(.appearance-bordered) {
      $color: $color-darkslategray;

      color: $color-white;
      border-color: $color-gray;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $color;
      }
    }
    &.appearance-bordered {
      $color: $color-gray;

      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15);
        }
      }
    }
  }
  &-blue {
    &:not(.appearance-bordered) {
      $color: $color-cornflowerblue;

      border-color: $color-cornflowerblue;
      color: $color-white;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $color;
      }
    }
    &.appearance-bordered {
      $color: $color-cornflowerblue;

      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15) !important;
        }
      }
    }
  }
  &-dark-blue {
    &:not(.appearance-bordered) {
      $color: $color-steelblue;

      border-color: $color-steelblue;
      color: $color-white;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $color;
      }
    }
    &.appearance-bordered {
      $color: $color-steelblue;

      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15);
        }
      }
    }
  }
  &-red {
    $color: $color-pureedpumpkin;

    &:not(.appearance-bordered) {
      border-color: $color;
      color: $color-white;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $color;
      }
    }
    &.appearance-bordered {
      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15) !important;
        }
      }
    }
  }
  &-secondary-gradient {
    $color: #e1e1e1;

    &:not(.appearance-bordered) {
      border-color: $color;
      color: #979797;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $gradient-secondary;
      }
    }
    &.appearance-bordered {
      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15) !important;
        }
      }
    }
  }
  &-warning {
    $color: $color-darkorange;

    &:not(.appearance-bordered) {
      border-color: $color;
      color: $color-white;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $color;
      }
    }
    &.appearance-bordered {
      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15) !important;
        }
      }
    }
  }
  &-secondary-gradient {
    $color: #e1e1e1;

    &:not(.appearance-bordered) {
      border-color: $color;
      color: #979797;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $gradient-secondary;
      }
    }
    &.appearance-bordered {
      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15) !important;
        }
      }
    }
  }
  &-warning {
    $color: $color-darkorange;

    &:not(.appearance-bordered) {
      border-color: $color;
      color: $color-white;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $color;
      }
    }
    &.appearance-bordered {
      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15) !important;
        }
      }
    }
  }
  &-danger {
    $color: #cb0000;

    &:not(.appearance-bordered) {
      border-color: $color;
      color: $color-white;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $color;
      }
    }
    &.appearance-bordered {
      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15) !important;
        }
      }
    }
  }
  &-green {
    &:not(.appearance-bordered) {
      $color: $color-lightseagreen;

      border-color: $color-lightseagreen;
      color: $color-white;

      &:hover {
        border-color: darken($color, 15);
      }

      & .content {
        color: currentColor;
        background: $color;
      }
    }
    &.appearance-bordered {
      $color: $color-lightseagreen;

      border-color: $color;
      color: $color;

      & .content {
        color: currentColor;
        background-color: transparent;
        @include transition(color, border-color);
      }
      &:hover {
        border-color: darken($color, 15);
        & .content {
          color: darken($color, 15) !important;
        }
      }
    }
  }
}
.shape {
  &-right-arrow {
    position: relative;
    overflow: visible;
    margin-right: 30px;
    display: flex;
    align-items: center;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -13px;
      width: 28.2px;
      height: 28.2px;
      transform: rotate(45deg);
      background: white;
      z-index: 1;
      border-right: 1px solid;
      border-top: 1px solid;
      border-color: inherit;
    }

    .content {
      position: relative;
      z-index: 2;

      &::after {
        content: "";
        display: block;
        position: absolute;
        right: -12px;
        width: 22.6px;
        height: 22.6px;
        transform: rotate(45deg);
        background: inherit;
      }
    }
  }

  &-left-arrow {
    position: relative;
    overflow: visible;
    margin-left: 30px;
    display: flex;
    align-items: center;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: -13px;
      width: 28.2px;
      height: 28.2px;
      transform: rotate(45deg);
      background: white;
      z-index: 1;
      border-left: 1px solid;
      border-bottom: 1px solid;
      border-color: inherit;
    }

    .content {
      position: relative;
      z-index: 2;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: -12px;
        width: 22.6px;
        height: 22.6px;
        transform: rotate(45deg);
        background: inherit;
      }
    }
  }
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
