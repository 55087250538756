@import "@styles/abstracts";

.data {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}
.item {
  text-align: center;

  &:not(:last-child) {
    border-right: 1px solid;
    border-color: $secondary-color;
  }
}
.value {
  font-size: 22px;
  line-height: 27px;
  font-weight: 900;
  color: $secondary-color;
}
.title {
  font-size: 14px;
  line-height: 16px;
  color: $color-black;
}
