.checkbox {
  @apply flex items-center;

  &:not(.disabled) {
    @apply cursor-pointer;
  }

  &.disabled {
    opacity: 0.6;
  }

  .checkbox-input {
    @apply bg-gray-cd text-black;
    @apply flex items-center justify-center flex-shrink-0 rounded transition-all;

    i {
      font-size: 80%;
      @apply transition-all;
    }
  }

  label {
    @apply ml-2;
  }

  &.checkbox-success {
    .checkbox-input {
      @apply text-white;
    }
  }

  &:not(.checked) {
    .checkbox-input i {
      opacity: 0;
    }
  }

  &.checked {
    &.checkbox-success {
      .checkbox-input {
        @apply bg-success;
      }
    }
  }

  &.checkbox-sm {
    @apply text-xs;

    .checkbox-input {
      @apply w-4 h-4;
    }
  }
}
