@import "@styles/abstracts";

.btn-outlined {
  width: auto;
  display: flex;

  span {
    grid-gap: $grid-gap-small;
  }
}
