.button-group {
  display: flex;
  align-items: stretch;

  button {
    background: white;
    color: #cdcdcd;
    border: 1px solid #cdcdcd;
    padding: 0.5rem;
    font-size: 0.75rem;
    line-height: 1;
    transition: all ease-in-out 0.2s;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &:not(:first-child) {
      border-left: 0;
    }

    &:hover:not(:disabled):not(.active) {
      @apply text-gray;
    }

    &.active {
      @apply bg-gray-d9;
      @apply text-black;
    }
  }

  &.invalid {
    button {
      @apply border-danger;
    }
  }
}
