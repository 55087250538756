@import "@styles/abstracts";

.table {
  color: $color-white;
}
.head {
  &-cell {
    &-sort-mode:hover,
    &-sorted {
      color: $color-darkorange;
    }
  }
}
