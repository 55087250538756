@import "@styles/abstracts";

.list {
  font-size: 14px;
  line-height: 19px;
  color: $color-darkslategray;
  &-row {
    display: flex;
    align-items: center;
    padding: 3px 7px 7px;
    &:nth-child(even) {
      background-color: rgba($color-gainsboro, 0.5);
    }
  }
  &-label {
    font-weight: 600;
  }
  &-value {
  }
}
