@import "@styles/abstracts/";

$userBlockChangeSize: xs;

.top-line {
  color: $color-cornflowerblue;
  padding: 13px 0;
}
.user-block {
  flex-shrink: 1;
  display: flex;

  @include media-breakpoint-up($userBlockChangeSize) {
    align-items: center;
  }

  @include media-breakpoint-down(sm) {
    width: fit-content !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.user-info {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-menu {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}
.icon {
  $maxSize: 18px;

  max-height: $maxSize;
  max-width: $maxSize;
  cursor: pointer;
  @include transition(color);

  @include media-breakpoint-up($userBlockChangeSize) {
    $maxSize: 20px;

    max-height: $maxSize;
    max-width: $maxSize;
  }

  &:hover {
    color: $secondary-color;
  }

  &-logout {
    stroke: currentColor;
  }
}
.username {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  width: 100%;
  text-align: right;
  margin-bottom: 5px;

  @include media-breakpoint-up($userBlockChangeSize) {
    width: auto;
    margin-bottom: 0;
  }

  @include media-breakpoint-down($userBlockChangeSize) {
    font-size: 12px;
  }
}
.role {
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  text-align: right;
  color: $color-graphitegray;

  @include media-breakpoint-down($userBlockChangeSize) {
    font-size: 10px;
  }
}
.item-wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 33.33%;

  @include media-breakpoint-up($userBlockChangeSize) {
    width: auto;
    display: block;
  }

  @include media-breakpoint-down(sm) {
    width: fit-content !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}
