@import "@styles/abstracts";

.level_1 {
  font-size: 24px;
  margin-bottom: 18px;
  font-weight: 700;

  @include media-breakpoint-up(xs) {
    font-size: 32px;
    line-height: 43px;
    margin-bottom: 30px;
  }
}

.level_2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 33px;
  margin-bottom: 25px;
}

.level_3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
}
