@import "@styles/abstracts";

.row {
  &:nth-child(n) {
    padding: 0;
  }
}
.item {
  display: flex;
  // align-items: stretch;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
}

.close {
  $closeSize: 18px;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: $closeSize;
  height: $closeSize;
  margin: 6px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    &:before,
    &:after {
      background-color: $secondary-color;
    }
  }

  $pseudoMargin: 7px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -2px;
    width: 4px;
    height: $closeSize + 3;
    background-color: $color-cornflowerblue;
    @include transition(background-color);
  }
  &:before {
    right: 35%;
    top: -10%;
    transform: rotate(45deg);
  }
  &:after {
    right: 35%;
    top: -10%;
    transform: rotate(135deg);
  }
}

.backdrop {
  position: absolute;
  width: 200px;
  height: 200px;
  transition-duration: 0.2s;
  background-color: rgba($color-gray, 0);
  border-radius: 50%;
  pointer-events: none;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  $size: 200px;

  width: $size;
  height: $size;
  border-radius: 50%;
  border: 2px solid;
  border-color: $color-cornflowerblue;
  background-color: $color-white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &-edit-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    width: 33px;
    height: 34px;
    padding: 6px;
    border-radius: 50%;
    border-color: $color-cornflowerblue;
    background-color: transparent;
    transition-duration: 0.2s;
    cursor: pointer;
    opacity: 0;

    &:hover {
      color: $secondary-color;

      & ~ .backdrop {
        background-color: rgba($color-gray, 0.2);
      }
    }
  }

  &-crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: dotted $color-white 1px;
  }

  &-crop-modal {
    max-width: 550px !important;
  }

  &-crop-container {
    margin: auto !important;
    width: 100%;
    min-height: 100px;
    border-style: solid;
    border-color: $color-cornflowerblue;
    border-width: 2px 0;

    img {
      margin: auto;
    }
  }

  &-crop {
    width: 250px !important;
    height: 250px !important;
    border-radius: 50%;
  }

  &-block {
    $paddingY: 15px;
    $size: 230px;

    width: $size;
    height: $size;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: $color-white;
    padding-top: $paddingY;
    padding-bottom: $paddingY;

    &:hover {
      .logo-edit-btn {
        opacity: 1;
      }
    }
  }
}

// TODO: Duplicate content. Move to personal button component when Peer will be activate

.assessments {
  $paddingY: 30px;

  display: flex;
  align-items: flex-end;
  padding-top: $paddingY;
  padding-bottom: $paddingY;

  &-item {
    $paddingY: 8px;
    $paddingX: 11px;

    width: 100%;
    border-radius: 3px;
    color: $color-white;
    padding: $paddingY $paddingX;

    &-denied {
      opacity: 0.5;
      background-color: $color-cornflowerblue;
    }
    &-peer {
      $color: $color-silver;

      order: 1;
      background-color: $color;
      border-color: $color;
      margin-bottom: 10px;
    }
  }
  &-date {
    font-size: 9px;
    line-height: 12px;
  }
  &-type {
    font-weight: 600;
  }
  &-content {
    position: relative;
    z-index: 20;
    display: flex;
    justify-content: space-between;
  }
  &-text {
    margin-right: 15px;
  }
  &-status {
    $size: 23px;

    flex-shrink: 1;
    width: $size;
    height: $size;
  }
  &-column {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
