@import "@styles/abstracts";

.quiz {
  padding: $grid-gap-medium $grid-gap-small;
  display: grid;
  grid-template-columns: 2fr 3fr;

  .left {
    padding: 0 $grid-gap-small;
    border-right: 1px solid $color-lighttelegrey;

    .desc {
      font-size: 1rem;
      margin-top: $grid-gap-medium;
    }

    .start-quiz-btn {
      margin-top: $grid-gap-medium;
    }

    .stats-container {
      margin-top: $grid-gap-small;

      .stat {
        display: flex;
        align-items: center;
        grid-gap: $grid-gap-small;
        color: $color-midnightblue;

        .stat-heading {
          font-size: 3.5rem;
        }

        .green {
          color: $color-seagreen;
        }

        .stat-details {
          display: flex;
          flex-direction: column;
          grid-gap: 6px;

          p {
            font-size: 1.1rem;
          }
        }
      }

      .divider {
        border-top: 1px solid $color-lighttelegrey;
        margin: $grid-gap-small 0;
      }
    }
  }

  .right {
    padding: 0 $grid-gap-small;

    .heading-start-quiz {
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: $grid-gap-medium;
    }

    .quiz-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .right-stats {
      font-weight: bold;
      font-size: 1rem;
      .red {
        color: $color-tomato;
      }
    }

    .heading-secondary {
      font-size: 1.3rem;
      //font-weight: bold;
      margin-top: $grid-gap-small;
      font-weight: bold;
      color: $color-midnightblue;

      .red {
        color: $color-tomato;
      }
    }

    .create-app {
      padding: $grid-gap-small;
      margin-top: $grid-gap-small;
      color: $color-midnightblue;
      font-size: 1rem;
      font-weight: bold;
      border: 1px solid $color-cornflowerblue;
      border-radius: 4px;
    }

    .options {
      margin-top: $grid-gap-medium;
      display: flex;
      flex-direction: column;
      grid-gap: $grid-gap-small;
    }

    .option {
      cursor: pointer;
      padding: $grid-gap-small;
      background: $color-lavender;
      color: $color-midnightblue;

      display: flex;
      grid-gap: $grid-gap-small;
      align-items: center;

      .dot {
        width: 14px;
        height: 14px;
        border-radius: 50%;
      }

      .non-selected-dot {
        background: $color-lighttelegrey;
      }

      .selected-dot {
        background: $color-cornflowerblue;
      }
    }

    .quiz-cta {
      margin-top: $grid-gap-medium;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      grid-gap: $grid-gap-small;

      .btn {
        width: auto;
      }
    }

    .result-heading {
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: $grid-gap-small;
      color: $color-midnightblue;

      .red {
        color: $color-pureedpumpkin;
      }

      .green {
        color: $color-seagreen;
      }
    }

    .stats-icon-container {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin: $grid-gap-medium auto;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .red-bg {
      background: $color-pureedpumpkin;
    }

    .green-bg {
      background: $color-seagreen;
    }

    .complete-quiz-cta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: $grid-gap-small;

      .btn {
        width: auto;
      }
    }
  }

  .heading {
    font-size: 1.3rem;
    font-weight: bold;
    color: $color-cornflowerblue;
  }
}
