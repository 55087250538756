@import "@styles/abstracts";

.page-container {
  margin: 0;
}

.title {
  margin-top: 2px;
  margin-left: 7px;
  margin-bottom: 17px;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $color-cornflowerblue;
}

.report-group-wrapper {
  padding: 0;
  background: $color-superlightgrey;
  border-radius: 10px;

  &:last-of-type {
    margin-top: 23px;
  }

  .report-title {
    padding: 10px 12px 6px;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.5px;
    color: $color-cornflowerblue;
  }

  .report-block {
    padding: 10px 12px 6.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 16.37px;
    color: $color-black;

    &:not(&:last-of-type) {
      border-top: 2px solid $color-white;
    }

    .action-wrapper {
      display: flex;

      .action {
        width: 22px;
        height: 16px;
        margin: 0 10px;
        color: $color-cornflowerblue;
      }
    }
  }
}
