@import "@styles/abstracts";

.header-wrapper {
  position: relative;
  padding: 18px 22px 0;
  background: $color-midnightblue;

  .header-title {
    padding-bottom: 12px;
    font-size: 26px;
    font-weight: 600;
    line-height: 35.46px;
    color: $color-white;

    .name {
      color: $color-lightorange;
    }
  }

  .header-divider {
    width: 100%;
    padding: 1px 0;
    border-bottom: 1px dashed $color-white;
  }

  .close-icon {
    position: absolute;
    top: 17px;
    right: 15px;
    width: 15px;
    height: 15px;
    transition-duration: 0.25s;
    color: $color-white;
    cursor: pointer;

    &:hover {
      color: $color-lightorange;
    }
  }
}

.tab-wrapper {
  padding: 11px 22px 0;
  background: $color-midnightblue;
  color: $color-white;

  .tab-item {
    padding-bottom: 3px;
    font-weight: 400;

    &:not(:last-child) {
      margin-right: 46px;
    }

    &::after {
      height: 3px;
    }
  }
}

.main-panel {
  min-width: 929px;
  min-height: 606px;
  padding: 31px 26px;
  display: none;
  background: $color-white;

  &.active {
    display: flex;
  }

  .pdf-panel {
    min-width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: $color-lightgray;
    color: $color-black;

    .year {
      border-bottom: 11px;
      font-size: 88px;
      font-weight: 700;
      line-height: 120px;
    }

    .desc {
      font-size: 32px;
      font-weight: 700;
      line-height: 44px;
    }
  }
}
