@import "@styles/abstracts/";

@mixin sortedCell {
  background: rgba($color-midnightblue, 0.05);
}

.head {
  &-row {
    border-bottom: 1px solid $color-gainsboro;
  }
  &-cell {
    &-sort-mode:hover,
    &-sorted {
      color: $color-midnightblue;
    }

    &-sorted {
      @include sortedCell();

      & [class*="ka-icon"] {
        &:before,
        &:after {
          border-color: $secondary-color;
        }

        $activeColor: $color-midnightblue;

        &[class*="up"]:before {
          border-color: $activeColor;
        }
        &[class*="down"]:after {
          border-color: $activeColor;
        }
      }
    }
  }
}
.data-row {
  &:nth-child(even) {
    background-color: $color-gainsboro;
  }
}
.body {
  color: $color-darkslategray;
}
.cell {
  &-inner {
    &-sorted {
      background: rgba($color-midnightblue, 0.05);
    }
  }
}
