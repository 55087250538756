@import "@styles/abstracts";

.main {
  background-color: $color-midnightblue;
  color: $color-white;
}
.row {
  $paddingY: 30px;

  border-bottom: 1px dashed;
  font-size: 18px;
  line-height: 25px;
  border-color: $color-steelblue;
  padding-bottom: $paddingY;

  &:not(:first-child) {
    padding-top: $paddingY;
  }
  &:last-child {
    border-bottom: none;
  }
}
