@import "@styles/abstracts";

.user {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.name {
  $color: $main-color;

  position: relative;
  cursor: pointer;
  color: $color;
  @include transition(color);

  &:hover {
    color: darken($color, 15%);
  }
}
