@import "@styles/abstracts";

.wrapper {
  position: relative;
}
.value {
  display: flex;
  align-items: center;
}
.hidden {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 300px;
  border: 1px solid $secondary-color;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.2;
  color: $color-gray;
  background-color: $color-white;
  transform: translate(-50%, 100%);
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  padding: 5px;
}
.item:not(:last-child) {
  margin-bottom: 3px;
}
.icon {
  cursor: pointer;
  @include transition(color);
  margin-left: 5px;

  &:hover {
    color: $secondary-color;
  }
}
