@import "@styles/abstracts";

.title {
  font-size: 16px;
  font-weight: 900;
  line-height: 21px;
  color: $color-cornflowerblue;
  margin-bottom: 1px;
}
.description {
  font-size: 12px;
  line-height: 16px;
  color: $color-midnightblue;
}
