@import "@styles/abstracts";

.footer {
  $paddingY: 10px;

  font-size: 12px;
  line-height: 1.4;
  color: $color-white;
  background-color: $color-darkslategray;
  padding-top: $paddingY;
  padding-bottom: $paddingY;

  @include media-breakpoint-up(xs) {
    $paddingY: 12px;

    padding-top: $paddingY;
    padding-bottom: $paddingY;
  }
  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
}
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(xs) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.link {
  @include transition(color);

  &:hover {
    color: $secondary-color;
  }
}
.copyright {
  $paddingY: 10px;

  text-align: center;
  color: darken($color-white, 35%);
  padding-top: $paddingY;
  padding-bottom: $paddingY;

  @include media-breakpoint-up(xs) {
    $paddingX: 10px;
    $paddingY: 0px;

    padding: $paddingY $paddingX;
  }
}
