@import "@styles/abstracts";

.button-span {
  margin-left: 10px;
  @include plusIcon($color-white, 2px);
}

.button {
  width: 150px;
}

.first-cell {
  color: $main-color;
}

.first-row {
  color: $secondary-color;
}

.row-break-text {
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  display: block;
  width: 90%;
}

.activated {
  color: $color-success;
}

.deleted {
  color: $color-pureedpumpkin;
}
