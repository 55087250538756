@import "@styles/abstracts";

.text-with-icon {
  display: flex;
  align-items: flex-start;
  grid-gap: $grid-gap-small;

  //.icon {
  //  width: 21px;
  //  height: 21px;
  //  flex-shrink: 0;
  //  background: $color-gainsboro;
  //  border-radius: 5px;
  //  color: transparent;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  margin-top: -2px;
  //}
  .title {
    color: $color-midnightblue;
    font-weight: bold;
  }

  .text {
    color: $color-midnightblue;
  }
}
