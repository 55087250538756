@import "@styles/abstracts";

.description {
  font-size: 12px;
  line-height: 16px;
  color: $color-black;
}
.textarea {
  min-height: 297px;
  height: 100%;
  font-size: 14px;
  line-height: 19px;

  &-wrapper {
    flex-grow: 1;
  }
}
.feedback {
  align-items: stretch;

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-title {
    flex-shrink: 0;
  }
}
