@import "@styles/abstracts";

$borderRadius: 3px;

.block {
  $color: $color-gainsboro;

  cursor: pointer;
  border-radius: $borderRadius;
  border: 1px solid;
  border-color: $color;
  @include transition(border-color);
  padding: 3px;

  &:hover {
    border-color: darken($color, 15%);
  }
}
.inner {
  position: relative;
  display: flex;
  align-items: stretch;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 50%;
    border-radius: $borderRadius;
    background-image: $gradient-orange;
    @include transition(left);
  }

  &-false:after {
    left: 50%;
  }
}
.item {
  position: relative;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  color: $color-silver;
  @include transition(color);
  padding: 14px 30px;

  &-active {
    color: $color-white;
  }
}
