@import "@styles/abstracts";

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  color: $color-midnightblue;

  & [class*="row"]:not(:last-child) {
    margin-bottom: 10px;
  }

  & [class*="label"] {
    width: 120px;
  }
}
.report {
  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    width: 150px;
    font-size: 10px;
    font-weight: inherit;
    line-height: 12px;
    padding: 1px;

    &-icon {
      width: 10px;
      margin-right: 5px;
    }
  }
}
