@import "@styles/abstracts";

.block {
  width: 100%;

  & [class*="content"] {
    justify-content: flex-start;
  }
}
.icon {
  $size: 14px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 1px solid;
  font-size: 10px;
  border-color: currentColor;
  margin-left: 5px;
}
.password-tooltip-wrapper {
  position: relative;
  left: 560px;
  bottom: 24px;

  & .detail {
    $borderColor: $color-gainsboro;
    $background: $color-white;

    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    text-transform: none;
    transform: translate3d(32px, 64px, 0px) !important;
    border: 1px solid;
    border-radius: 3px;
    background-color: $background;
    border-color: $borderColor;
    color: currentColor;
    @include transition(opacity);
    padding: 10px;

    &:before {
      $size: 10px;
      $borderSize: 1px;

      content: "";
      position: absolute;
      top: 50%;
      left: -$size;
      z-index: 10;
      width: $size;
      height: $size;
      transform: rotate(45deg) translate(50%, -50%);
      transform-origin: top right;
      border-style: solid;
      border-width: 0 0 $borderSize $borderSize;
      border-color: $borderColor;
      background-color: $background;
    }

    &-title {
      font-weight: 800;
      margin-bottom: 5px;
    }
  }

  &:hover .detail {
    z-index: 10;
    opacity: 1;
    pointer-events: auto;
  }
}
