@import "@styles/abstracts/";

.wrapper {
  min-height: 100%;
  flex: 0 0 236px;
  padding: 15px 24px;
  background: $color-midnightblue;
}

.container {
  position: sticky;
  top: 0;
}

.title {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  padding: 3px 0 7px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  color: $color-white;

  &:not(&:first-of-type) {
    margin-top: 20px;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% + 30px);
    border-bottom: 2px solid #3478bc;
  }
}

.item-wrapper {
  position: relative;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 200;
  color: $color-lightorange;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% + 30px);
    border-bottom: 2px solid #3478bc;
  }

  .item-header {
    padding: 7.5px 0;
    font-size: 14px;

    &.active {
      .indicator {
        transform: rotate(90deg);
      }
    }

    .indicator {
      margin-left: 6px;
      width: 9px;
      height: 9px;
      transition-duration: 0.25s;
    }
  }

  .menu-item {
    max-width: 160px;
    padding: 7.5px 28px 7.5px 5px;
    margin-right: -28px;
    font-size: 14px;
    text-align: right;
    color: $color-white;

    &:last-of-type {
      margin-bottom: 6px;
    }

    &.active,
    &:hover {
      background: $color-white;
      color: $color-midnightblue;
    }
  }
}
