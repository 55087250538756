@import "@styles/abstracts";

.select {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
$textMargin: 5px;

.text-pre {
  margin-right: $textMargin;
}
.text-post {
  margin-left: $textMargin;
}
