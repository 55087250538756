@import "@styles/abstracts";

.action-button {
  white-space: nowrap;
  color: $color-royalblue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: darken($color-royalblue, 10);
  }
}
