@import "@styles/abstracts";

@mixin swiperArrow {
  content: " ";
  width: 12px;
  height: 12px;
  border: solid;
  border-color: $color-darkorange;
  border-width: 0 2px 2px 0;
}

.carouselContainer {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: $grid-gap;
  border-bottom: 1px solid $color-gainsboro;
}

.seeAll {
  display: flex;
  align-items: center;
  grid-gap: $grid-gap-small;
  cursor: pointer;
}

.arrow {
  @include flexCenter;
  align-self: center;
  width: 37px;
  height: 37px;
  z-index: 15;
  position: relative;
  cursor: pointer;
  background: $color-white;
  border-radius: 50%;
  //box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.arrowLeft {
  &:after {
    @include swiperArrow;
    transform: rotate(135deg);
    margin-left: 5px;
  }
}

.arrowRight {
  &:after {
    @include swiperArrow;
    transform: rotate(-45deg);
    margin-right: 5px;
  }
}

.mySwiper {
  padding: 2rem 1rem !important;
  position: relative;
  flex-shrink: 0;
  overflow: hidden !important;
  width: 100%;
}

.swiperSlide {
  max-width: 211px;
  width: 100%;
  height: 100%;
  //background: $color-white;
  //box-shadow: 0 10px 30px rgba(22, 58, 95, 0.05);

  //border-radius: 4px;
  overflow: hidden;
}

.noCourseToShow {
  max-width: 500px;
  margin: 6rem auto 2rem;
  font-size: 22px;
  font-family: $font-nunito;
  text-align: center;
}
