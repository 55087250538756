.html-viewer {
  $marginBottom: 20px;

  & h3 {
    font-weight: 700;
    margin-bottom: 10px;
  }
  & p {
    margin-bottom: $marginBottom;
  }

  & ul {
    margin-bottom: $marginBottom;

    & li {
      $paddingY: 5px;

      position: relative;
      padding-top: $paddingY;
      padding-bottom: $paddingY;
      padding-left: 15px;

      &:before {
        $size: 4px;

        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        display: block;
        width: $size;
        height: $size;
        border-radius: 50%;
        overflow: hidden;
        background-color: currentColor;
      }
    }
  }
  i {
    font-style: italic;
  }
  b {
    font-weight: bold;
  }
}
