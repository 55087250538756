@import "@styles/abstracts";

.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.block {
  $marginX: auto;

  max-width: 700px;
  margin-right: auto;
  margin-left: auto;

  & > [class*="content"] {
    $paddingY: 15px;

    padding-top: $paddingY;
    padding-bottom: $paddingY;

    @include media-breakpoint-up(xs) {
      padding-top: 50px;
      padding-bottom: 44px;
    }
  }
}
.inner {
  max-width: 560px;
  margin-right: auto;
  margin-left: auto;
}
