@import "@styles/abstracts";

$marginItemX: 0;
$paddingItemX: 10px;

@include media-breakpoint-up(md) {
  .inherit-height {
    min-height: inherit;
  }
}

.menu {
  display: block;
  height: 100%;
  min-height: inherit;
  width: 100%;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }
}
.list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 30px;
  padding: 0 30px;
}
.link {
  min-height: inherit;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $color-white;
  background-color: transparent;
  cursor: pointer;

  @include adaptiveDefaultGap(false, padding);
  @include transition(background-color);

  &:hover,
  &-active {
    background-color: $color-white;
    color: $color-primary;

    @include media-breakpoint-down(md) {
      $radius: 3px;

      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  @include media-breakpoint-up(md) {
    $paddingX: $paddingItemX;
    $paddingY: 0;

    font-size: 10px;
    line-height: 14px;
    font-weight: 900;
    padding: $paddingY $paddingX;
  }
}
