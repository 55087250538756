@import "@styles/abstracts";

.legend {
  flex-shrink: 0;
  font-size: 12px;
  line-height: 13px;
  color: $color-darkslategray;

  &-score {
    color: $main-color;
  }
  &-target {
    color: $secondary-color;
  }
}
.value {
  color: $color-cornflowerblue;
}
.target {
  color: $secondary-color;
}
.item {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  &-title {
    font-size: 18px;
    font-style: italic;
    line-height: 24px;
    margin-bottom: 10px;
  }
  &-row:not(:last-child) {
    margin-bottom: 10px;
  }

  @mixin dotted {
    background-image: linear-gradient(90deg, transparent 0%, transparent 85%, $color-white 85%, $color-white);
    background-size: 20px 1px;
  }

  &-line {
    background-color: $color-gainsboro;
    @include dotted();

    & [class*="inner"] {
      @include dotted();
    }
  }
}
