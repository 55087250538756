@import "@styles/abstracts";

.chart-container {
  margin-top: $grid-gap-small;
}

.progress {
  position: relative;
  margin: 4px;
  float: left;
  text-align: center;
}

.bar-overflow {
  /* Wraps the rotating .bar */
  position: relative;
  width: 250px;
  height: 125px; /* Half circle (overflow) */
  margin-bottom: -14px; /* bring the numbers up */
  overflow: hidden;
}

.progress-value {
  color: $color-black;
  font-weight: bold;
  font-size: 28px;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: 250px; /* full circle! */
  border-radius: 50%;
  box-sizing: border-box;
  border: 50px solid $color-steelblue; /* half gray, */
  border-bottom-color: $color-gainsboro; /* half azure */
  border-right-color: $color-gainsboro;

  // -135 to 45 rotation 1% 1.8deg rotation
  transform: rotate(-135deg);
}
