@import "@styles/abstracts";

.icon {
  cursor: pointer;
  @include transition(color);

  &:hover {
    color: $secondary-color;
  }
}
