@import "@styles/abstracts";

.close {
  $closeSize: 12px;

  position: relative;
  width: $closeSize;
  height: $closeSize;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    &:before,
    &:after {
      background-color: $secondary-color;
    }
  }

  $pseudoMargin: 5px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 2px;
    height: $closeSize + 1;
    background-color: rgba($secondary-color, 0.8);
    @include transition(background-color);
  }
  &:before {
    right: $pseudoMargin;
    transform: rotate(45deg);
  }
  &:after {
    left: $pseudoMargin;
    transform: rotate(-45deg);
  }
}
