@import "@styles/abstracts";

.notes {
  padding: $grid-gap-medium $grid-gap-small;
  display: grid;
  grid-template-columns: 2fr 3fr;

  .left {
    padding: 0 $grid-gap-small;
    border-right: 1px solid $color-lighttelegrey;

    .desc {
      font-size: 1rem;
      margin-top: $grid-gap-medium;
    }

    .checklist {
      margin-top: $grid-gap-medium;
    }

    .checklist-list {
      margin-top: $grid-gap-small;
      display: flex;
      justify-content: center;
      flex-direction: column;
      grid-gap: $grid-gap-small;
      //margin-top: $grid-gap-medium;
    }
  }

  .right {
    padding: 0 $grid-gap-small;

    .notes-input {
      //height: 100%;
      margin: 1.5rem 0;
      border: 1px solid #cdcdcd;
      border-radius: 4px;
      padding: 1rem;
    }

    .save-btn {
      margin-left: auto;
    }
  }

  .heading {
    font-size: 1.3rem;
    font-weight: bold;
    color: $color-cornflowerblue;
  }
}
