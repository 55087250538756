@import "@styles/abstracts";

.item:nth-child(2) {
  text-align: center;
}
.list {
  align-items: center;
}
.special {
  color: $secondary-color;
}
