@import "@styles/abstracts";

.switch {
  width: 2.25rem;
  height: 1.25rem;
  background: #a8a8a8;
  border: 1px solid #cdcdcd;
  border-radius: 10rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  transition: all ease-in-out 0.2s;

  &:not(.disabled):not(.readonly) {
    cursor: pointer;
  }

  .button {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background: white;
    position: absolute;
    left: 0.1rem;
    transition: all ease-in-out 0.2s;
  }

  &.switch-on {
    &.switch-primary {
      background: $color-primary;
    }

    &.switch-success {
      background: $color-success;
    }

    .button {
      left: calc(100% - 1.1rem);
    }
  }
}
