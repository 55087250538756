@import "@styles/abstracts";

.logo-wrapper {
  display: inline-flex;
  align-items: center;
  font-size: 8px;
  @include adaptiveDefaultGap(false, margin-right);

  @include media-breakpoint-up(md) {
    font-size: 10px;
  }

  &-linked {
    cursor: pointer;
    .icon-special {
      @include transition(color);
    }

    &:hover .icon-special {
      color: $secondary-color;
    }
  }
}
.icon-logo {
  $size: 2.7em;

  height: $size;
  width: $size;
  margin-right: 1em;
}
.icon-special {
  color: $color-cornflowerblue;
}
.text {
  font-family: $font-montserrat;
  font-size: 24px;
  font-weight: 800;
  color: $color-silver;

  @include media-breakpoint-down(md) {
    font-size: 20px;
  }
}
