@import "@styles/abstracts";

// TODO: find method for styling select. Current method is bad

.select {
  $valuePaddingX: 9px;
  $borderRadius: 3px;
  $borderColor: $color-silver;
  $border: 1px solid $borderColor;

  cursor: pointer;
  width: 100%;
  font-size: 18px;
  color: $main-color;

  &:hover {
    //current
    .select__control,
    .select__control ~ .select__menu {
      border-color: darken($borderColor, 15) !important;
    }
    .select__control--menu-is-open {
      border-bottom-color: transparent !important;
    }
  }

  & &__control {
    // reset

    height: 33px;
    min-height: unset;
    //background-color: transparent;
    //border: none;
    transition: none;
    box-shadow: none;
    background: $color-white;

    // current

    cursor: pointer;
    border: $border;
    border-radius: $borderRadius;

    &:hover {
      // reset
      border-color: transparent;
    }
    &--menu-is-open {
      border-bottom-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  & &__value-container {
    // reset

    display: flex;
    padding: 0;

    // current

    padding: 5px $valuePaddingX 3px;
    flex: 1 1;
    flex-wrap: nowrap;
  }

  & &__placeholder {
    //reset

    margin-right: 0;
    margin-left: 0;

    // current

    color: $placeholder-color;
  }

  & &__single-value {
    // reset

    margin: 0;
    color: currentColor;
  }
  & [class*="DummyInput"] {
    // reset

    position: absolute;
  }

  & &__indicators {
    // reset

    flex-shrink: 0;
    color: $color-silver;
  }
  & &__indicator {
    // reset

    color: currentColor;
    padding: 0;

    // current

    $paddingX: 5px;

    padding-right: $paddingX;
    padding-left: $paddingX;

    & svg {
      // current

      $size: 20px;

      width: $size;
      height: $size;
    }
  }

  & &__indicator-separator {
    // reset

    display: none;
  }
  & &__menu {
    //reset

    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    margin: 0;

    // current
    margin-bottom: 15px;
    background-color: $color-white;
    border: $border;
    border-top: none;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }
  & &__menu-list {
    // reset

    padding: 0;

    // current

    // padding-top: 8px;
  }
  & &__option {
    // reset

    cursor: pointer;
    padding: 0;

    // current
    $paddingY: 4px;

    padding: $paddingY $valuePaddingX;

    &--is-focused,
    &--is-selected {
      // reset

      background-color: transparent;
      color: currentColor;

      // current

      background-color: $main-color;
      color: $color-white;
    }
  }
}
