@import "@styles/abstracts";

.title {
  color: $color-midnightblue;
  margin-top: 30px;
}

.resources {
  grid-gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 12px;
}
