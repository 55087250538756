@import "@styles/abstracts";

.item {
  $paddingX: 3px;

  padding-right: $paddingX;
  padding-left: $paddingX;
}
.cell {
  font-size: 12px;
  line-height: 14px;
}
