@import "@styles/abstracts";

.carouselHeader {
  @include flex-between;
  padding-bottom: $grid-gap-small;
  border-bottom: 1px solid $color-gainsboro;
}

.carouselHeading {
  font-size: 14px;
  font-weight: bold;
}

.description {
  font-size: 12px;
  color: $color-black;
  margin-top: 6px;
}
.right-side {
  display: flex;
  justify-content: flex-end;
}
