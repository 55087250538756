@import "@styles/abstracts";

.content {
  color: $color-darkslategray;
}
.list {
  border-bottom: 1px solid currentColor;
  margin-bottom: 20px;
}
.row {
  &:not(:last-child) {
    $marginBottom: 15px;

    border-bottom: 1px dashed currentColor;
    padding-bottom: $marginBottom;
    margin-bottom: $marginBottom * 2;
  }
}
.title {
  color: $main-color;
}
