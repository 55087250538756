@import "@styles/abstracts/";

.wrapper {
  position: relative;
}
.dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 15;
  padding: 5px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: none;

  &-item {
    padding: 0.3rem 1rem;
    color: black;
    font-size: 0.875rem;
    cursor: pointer;

    &:hover {
      background: #eaeef0;
    }
  }
}

.opened {
  .dropdown {
    display: block;
  }
}
