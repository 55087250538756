@import "@styles/abstracts";

@mixin gray-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-darkgray;
  padding: $grid-gap-small;

  grid-gap: $grid-gap-small;
  //margin-top: 4px;

  .title {
    font-size: 1rem;
    font-weight: 600;
    color: $color-midnightblue;
  }
}

.course-card {
  //@include card;
  border-bottom: 1px solid $color-gainsboro;
  padding-bottom: $grid-gap-medium;

  position: relative;

  display: flex;
  //align-items: center;
  grid-gap: $grid-gap-medium;

  .status {
    position: absolute;
    top: 0;
    right: $grid-gap-medium;
    display: flex;
    align-items: center;
    grid-gap: $grid-gap-small;

    .icon {
      color: transparent;
    }

    .text {
      color: $color-gray;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 12px;
    }

    .not-started {
      color: $color-cornflowerblue;
    }

    .in-progress {
      color: $color-firebrick;
    }

    .completed {
      color: $color-seagreen;
    }
  }

  .course-img-container {
    position: relative;

    .course {
      @include gray-card;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    .course-img {
      width: 252px;
      object-fit: cover;
    }
  }

  .course-details {
    color: $color-midnightblue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      margin-bottom: 4px;
      font-size: 20px;
    }

    .about {
      color: $color-cornflowerblue;
      font-weight: bold;
    }

    .desc {
      margin: $grid-gap-small 0;
      font-size: 14px;
    }

    .learners {
      font-weight: bold;
      color: $color-midnightblue;
    }
  }
}
