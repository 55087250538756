@import "@styles/abstracts";

.block {
  width: 100%;

  &-line {
    height: 3px;
  }
  &-rectangle {
    height: 8px;
    background-color: $color-white;
  }

  &-inner {
    height: 100%;
    background-color: currentColor;
    @include transition(width);
  }
}
