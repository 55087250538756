@import "@styles/abstracts";

.table {
  color: $color-white;
}
.head {
  margin-bottom: 7px;

  &-row {
    border-bottom: 1px solid rgba($color-gainsboro, 0.25);
  }
  &-cell {
    &-sort-mode:hover,
    &-sorted {
      color: $color-darkorange;
    }
  }
}
.body {
  font-size: 12px;
  line-height: 16px;
}
