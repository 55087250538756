@import "@styles/abstracts";

.modal {
  position: relative;
  width: 1060px;
  min-height: 730px;
  & [class*="header"] {
    padding-bottom: 0;
  }
}
.header-right {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: auto;
}
.tooltip {
  $size: 16px;
  $color: $color-white;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid $color;
  font-size: 10px;
  line-height: 12px;
  margin-right: 5px;
  @include transition(color, border-color);

  &:hover {
    $newColor: $color-darkgray;

    border-color: $newColor;
    color: $newColor;
  }
}
