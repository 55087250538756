@import "@styles/abstracts";

$linesPaddingX: 10px;
$borderSideStyle: 1px solid rgba($color-gainsboro, 0.25);

.row {
  &:not(:last-child) {
    border-bottom: $borderSideStyle;
  }
}

.line {
  $paddingY: 26px;

  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 35px;
  background-color: transition;
  @include transition(background-color);
  padding: $paddingY $linesPaddingX;

  &:hover {
    background-color: rgba($color-cornflowerblue, 0.25);
  }
}

// TODO: Move button styles from here and from detail button to abstract

.button {
  $size: 0.8128em;
  $width: 3px;

  flex-shrink: 0;
  position: relative;
  width: $size;
  height: $size;
  margin-right: 17px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 3px;
    background-color: $color-darkorange;
    @include transition(opacity, background-color);
  }

  &:before {
    top: 0;
    left: 50%;
    height: $size;
    width: $width;
    transform: translateX(-50%);
  }
  &:after {
    top: 50%;
    left: 0;
    height: $width;
    width: $size;
    transform: translateY(-50%);
  }
  &-hide:before {
    opacity: 0;
  }
}

.content {
  $paddingX: 38px;
  $paddingXFinal: $paddingX + $linesPaddingX;

  display: none;
  padding: 0 $paddingXFinal 15px;

  &-show {
    display: block;
  }
}
.modal-button {
  margin-left: auto;
}
