@import "@styles/abstracts";

$marginItemX: 0;
$paddingItemX: 10px;

@include media-breakpoint-up(md) {
  .inherit-height {
    min-height: inherit;
  }
}

.menu {
  display: block;
  height: 100%;
  min-height: inherit;
  width: 100%;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }
}
.list {
  height: 100%;
  min-height: inherit;

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: stretch;
  }
}
.item {
  @include media-breakpoint-up(md) {
    $marginX: $marginItemX;

    min-height: inherit;

    @if $marginX != 0 {
      &:not(:last-child) {
        margin-right: $marginX;
      }
      &:not(:first-child) {
        margin-left: $marginX;
      }
    }
  }
}
.link {
  min-height: inherit;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $color-white;
  background-color: transparent;

  @include adaptiveDefaultGap(false, padding);
  @include transition(background-color);

  &:hover,
  &-active {
    background-color: $color-white;
    color: $main-color;

    @include media-breakpoint-down(md) {
      $radius: 3px;

      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &.active-log {
    &:hover,
    &.link-active {
      background-color: $color-primary !important;
      color: $color-white !important;
    }
  }

  @include media-breakpoint-up(md) {
    $paddingX: $paddingItemX;
    $paddingY: 0;

    font-size: 10px;
    line-height: 14px;
    font-weight: 900;
    padding: $paddingY $paddingX;
  }
}
.icon {
  $maxSize: 18px;

  margin-right: 10px;
  max-height: $maxSize;
  max-width: $maxSize;
  cursor: pointer;
  @include transition(color);

  @include media-breakpoint-up(xs) {
    $maxSize: 20px;

    max-height: $maxSize;
    max-width: $maxSize;
  }

  &:hover {
    color: $secondary-color;
  }

  &-logout {
    stroke: currentColor;
  }
}
.mobile-divider {
  @include media-breakpoint-up(sm) {
    & ~ .item {
      display: none !important;
    }
  }

  @include media-breakpoint-down(sm) {
    width: calc(100% + 30px);
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid $color-midnightblue;
  }
}

@include media-breakpoint-down(md) {
  .wrapper {
    padding-left: 0;
  }
}
