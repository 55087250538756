@import "@styles/abstracts";

.wrapper {
  display: flex;
  align-items: center;
  grid-gap: $grid-gap-small;

  .text {
    color: $color-darkslategray;
    font-size: 1rem;
  }
}
