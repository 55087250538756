@import "@styles/abstracts";

.notify {
  &-title {
    margin-bottom: 28px;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -0.25px;
    color: $color-midnightblue;
  }

  &-description {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
  }

  &-button {
    @include media-breakpoint-up(xxs) {
      margin-left: auto;
    }
    @include media-breakpoint-down(xxs) {
      width: 100%;
    }
  }
}
