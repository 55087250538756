@import "@styles/abstracts";

@mixin linearTransition {
  transition: all 0.1s linear;
}

input.switch {
  cursor: pointer;
  flex-shrink: 0;
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 44px;
  height: 22px;
  border: 1px solid $color-gainsboro;
  border-radius: 50px;
  @include linearTransition;
}

input.switch:after {
  transition: all 0.3s linear;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(4px);
  left: 0px;
  background: transparent;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  @include linearTransition;
}

input.switch:checked {
  box-shadow: inset 20px 0 0 0 $color-gainsboro;
  background-color: $color-gainsboro;
  @include linearTransition;
}

input.switch:checked:after {
  //right: 2px;
  transform: translateY(-50%) translateX(24px);
  //left: unset;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
  background: $color-gray;
  @include linearTransition;
}
