/**
 * Grid
 */

$container-max-width: 1200px;
$container-adaptive-max-width: 1160px;
$grid-columns-count: 12;
$grid-gap: 15px;
$grid-gap-small: 12px;
$grid-gap-medium: 30px;
$grid-gap-large: 40px;

/**
 * Breakpoins
 */

$media-xl: 1440px;
$media-lg: 1280px;
$media-md: 992px;
$media-sm: 767px;
$media-xs: 576px;
$media-xxs: 480px;
$media-xxxs: 374px;

$media-sizes: (
  "xl": $media-xl,
  "lg": $media-lg,
  "md": $media-md,
  "sm": $media-sm,
  "xs": $media-xs,
  "xxs": $media-xxs,
  "xxxs": $media-xxxs
);

// families

$font-fallback: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
$font-nunito: "Nunito", $font-fallback;
$font-montserrat: "Montserrat", $font-fallback;
$font-main: $font-nunito;

$transition-main: 0.3s cubic-bezier(0.16, 0.16, 0.4, 0.82);

// Modal
$modalRowPaddingX: 20px;
