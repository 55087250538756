@import "@styles/abstracts";

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;
}
.buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.button {
  &:not(:last-child) {
    margin-right: 5px;
  }
  &-item {
    width: 135px;
    text-align: center;

    & [class*="content"] {
      $paddingX: 10px;

      justify-content: center;
      padding-right: $paddingX;
      padding-left: $paddingX;
    }
  }
}
.filter-block {
  width: 360px;
  max-width: 360px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-size: 18px;
  line-height: 25px;
  color: $color-graphitegray;

  &-title {
    margin-right: 8px;
    white-space: nowrap;
  }
}
