@import "@styles/abstracts/";

.body {
  font-size: 12px;
  line-height: 22px;
}
.cell {
  $paddingY: 6px;
  padding-top: $paddingY;
  padding-bottom: $paddingY;
}
