@import "@styles/abstracts";

.cell-container {
  position: relative;
  width: 100%;
  font-weight: 400;
  text-align: center;

  &.percentage {
    text-align: center;
  }

  p {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    height: 100%;

    &.blue {
      background: rgba($color-cornflowerblue, 0.2);
    }

    &.green {
      background: rgba($color-grassgreen, 0.3);
    }

    &.orange {
      background: rgba($color-lightorange, 0.3);
    }

    &.red {
      background: rgba($color-red, 0.2);
    }

    &.purple {
      background: rgba($color-purple, 0.2);
    }
  }
}
