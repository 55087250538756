@import "@styles/abstracts";

.category-container {
  margin: $grid-gap-small 0;
  font-weight: bold;
}

.category {
  $color: $color-darkorange;
  cursor: pointer;

  color: $color;
  @include transition(color);

  &-root:hover {
    color: darken($color, 15%);
  }
}
