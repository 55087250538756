@import "@styles/abstracts";

.control-line-select {
  max-width: 360px !important;

  & .select__control {
    height: auto !important;
  }

  & .select__indicators {
    color: $secondary-color !important;
  }
  & .select__indicator {
    $paddingX: 15px;

    padding-right: $paddingX !important;
    padding-left: $paddingX !important;

    & svg {
      // current

      $size: 14px;

      width: $size !important;
      height: $size !important;
    }
  }
}
