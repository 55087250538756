@import "@styles/abstracts";

.item {
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 7px;

  &:nth-child(even) {
    background-color: rgba($color-gainsboro, 0.5);
  }
}
.question {
  &-title {
    font-weight: 800;
    margin-bottom: 7px;
  }
}
.skill {
  &-row:not(:last-child) {
    margin-bottom: 5px;
  }
  &-target {
    color: $secondary-color;
  }
  &-self {
    color: $main-color;
  }
}
