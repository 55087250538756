@import "@styles/abstracts";

.status {
  font-weight: 600;
}
.active {
  color: $secondary-color;
}
.pending {
  color: $main-color;
}
.deactivated {
  color: $color-graphitegray;
}
.deleted {
  color: $color-pureedpumpkin;
}
