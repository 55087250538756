@import "./../abstracts/";

$inputTextSize: 33px;
$inputTextSizeMobile: 26px;
$passwordIconPaddingX: 5px;
$passwordIconSize: $inputTextSize + $passwordIconPaddingX * 2;
$errorColor: $color-orangered;

.row {
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;

    @include media-breakpoint-up(xs) {
      margin-bottom: 25px;
    }
  }
}
.item {
  width: 100%;

  &-label {
    font-size: 14px;
    line-height: 19px;
  }

  &-horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .item-label {
      flex-shrink: 0;
      color: $color-midnightblue;
      margin-right: 17px;
    }
  }

  &-vertical {
    width: 100%;

    & .item-label {
      color: $color-gray;
      margin-bottom: 2px;
    }
  }
}
.field {
  position: relative;
  width: 100%;

  // &-title {
  //   color: $placeholder-color;
  //   font-weight: 600;
  //   margin-bottom: 12px;
  // }

  &::after {
    content: attr(data-error);
    position: absolute;
    left: 0;
    bottom: -2px;
    z-index: 0;
    transform: translateY(100%);
    font-size: 12px;
    line-height: 14px;
    color: $errorColor;
  }
}
.text {
  $padding: 10px;

  font-size: 14px;
  line-height: 25px;
  border: 1px solid;
  border-radius: 3px;
  padding-right: $padding;
  padding-left: $padding;

  @include media-breakpoint-up(xs) {
    font-size: 18px;
  }

  &.textarea {
    padding-top: $padding;
    padding-bottom: $padding;
  }

  &-gray {
    $placeholderColor: $placeholder-color;
    $borderColor: $color-gainsboro;
    color: $main-color;
    border-color: $borderColor;

    @include placeholder() {
      color: $placeholderColor;
    }

    @include transition(border-color);
    &:focus {
      border-color: $main-color;
    }

    & ~ .password-icon {
      color: $placeholderColor;
      @include transition(color);

      &:hover,
      &__checked {
        color: $main-color;
      }
    }
  }
  &-orange {
    $placeholderColor: $main-color;
    $borderColor: $secondary-color;
    color: currentColor;
    border-color: $borderColor;

    @include placeholder() {
      color: $placeholderColor;
    }

    @include transition(border-color);
    &:focus {
      border-color: $main-color;
    }

    & ~ .password-icon {
      color: $placeholderColor;
      @include transition(color);

      &:hover,
      &__checked {
        color: $borderColor;
      }
    }
  }
}
.input {
  height: $inputTextSizeMobile;
  line-height: $inputTextSizeMobile;

  @include media-breakpoint-up(xs) {
    height: $inputTextSize;
    line-height: $inputTextSize;
  }
}
.input-error {
  border-color: $errorColor;
}
.password-input {
  padding-right: $passwordIconSize;
}
.password-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  height: 100%;
  max-width: $passwordIconSize;
  cursor: pointer;
  padding-right: $passwordIconPaddingX;
  padding-left: $passwordIconPaddingX;
}

.textarea {
  resize: none;
}

// TODO update select component styles

.select {
  &-control:focus {
    border-bottom: none;
  }
  &-list {
    $borderWidth: 1px;

    position: absolute;
    right: 0;
    bottom: 1px;
    left: 0;
    z-index: 100;
    border: solid $main-color;
    border-width: 0 $borderWidth $borderWidth $borderWidth;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: $color-white;
    transform: translateY(100%);
  }
  &-option {
    $paddingY: 4px;
    $valuePaddingX: 9px;

    cursor: pointer;
    font-size: 18px;
    padding: $paddingY $valuePaddingX;

    &:hover {
      background-color: $main-color;
      color: $color-white;
    }
  }
}
.label {
  display: flex;
  align-items: center;
  cursor: pointer;

  &-text {
    @include transition(color);
  }

  &-gray {
    & .label-text {
      color: $placeholder-color;
    }
    & .radio-check {
      background-color: $color-whitesmoke;

      &:not(.radio-error) {
        border-color: $secondary-color;
      }
    }
    & .checker:checked {
      $checkedColor: $color-royalblue;

      & + .radio-check {
        border-color: $checkedColor;

        &:after {
          background-color: $checkedColor;
        }
      }
      & ~ .label-text {
        color: $checkedColor;
      }
    }
  }
}
.radio {
  &-check {
    $size: 21px;

    flex-shrink: 0;
    position: relative;
    height: $size;
    width: $size;
    border-radius: 50%;
    border: 1px solid;
    margin-right: 10px;
    @include transition(border-color);

    &:after {
      $size: 9px;

      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      pointer-events: none;
      opacity: 0;
      width: $size;
      height: $size;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      @include transition(opacity);
    }
  }
  &-error {
    border-color: $errorColor;
  }
  &-label:not(:last-child) {
    margin-bottom: 7px;
  }
}
.hide {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 1;
}
.checker:checked + .radio-check:after {
  pointer-events: auto;
  opacity: 1;
  z-index: 10;
}
.calendar {
  position: absolute;

  &-input {
    cursor: pointer;
  }

  &-hide {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
  }
  &-show {
    opacity: 1;
    pointer-events: auto;
    z-index: 10;
  }
}

.dir {
  &-horizontal {
    .radio-label {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

.size {
  &-sm {
    font-size: 0.875rem;

    .radio-check {
      width: 16px;
      height: 16px;
    }
  }
}

.number-spinner {
  width: auto;

  .input {
    height: 1.75rem;
    max-width: 6rem;
    text-align: center;
    border: 1px solid #cdcdcd;
    border-radius: 0;

    &:disabled {
      color: #808184;
    }
  }

  button {
    width: 2rem;
    color: white;
    background: #cdcdcd;
    font-weight: bold;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }
    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  &.theme-primary {
    button:not(:disabled) {
      @apply bg-primary;
    }
  }
}
