.icon {
  $size: 1em;

  display: inline-block;
  width: $size;
  height: $size;
  fill: currentColor;
}

.svg {
  position: relative;
  display: inline-block;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    fill: currentColor;
  }
}

.svg-logo {
  width: 27px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-logo_text_engine {
  width: 86px;

  &::after {
    content: "";
    display: block;
    padding-bottom: (26/86) * 100%;
  }
}

.svg-logo_text_skills {
  width: 65px;

  &::after {
    content: "";
    display: block;
    padding-bottom: (20/65) * 100%;
  }
}

.svg-eye {
  width: 512px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-menu {
  width: 24px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-logout {
  width: 32px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-user {
  width: 32px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-chat {
  width: 23px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-chat-double {
  width: 23px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-pdf {
  width: 14px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-settings {
  width: 32px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-edit {
  width: 21px;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
.svg-download {
  width: 22px;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
// TODO: Remove old logo
.svg-logo-new {
  width: 27px;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
.svg-external-link {
  width: 22px;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-arrow-sm {
  width: 10px;
  height: 10px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-star {
  width: 12px;
  height: 12px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-heart-red {
  width: 12px;
  height: 12px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-heart-gray {
  width: 12px;
  height: 12px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-clock-history {
  width: 12px;
  height: 12px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-clock-completed {
  width: 16px;
  height: 12px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-arrow-down {
  width: 12px;
  height: 12px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-circle-question {
  width: 16px;
  height: 16px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-star-filled {
  width: 12.52px;
  height: 12.52px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-star-outlined {
  width: 12.52px;
  height: 12.52px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-bookmark {
  width: 11.9px;
  height: 16px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

@mixin feature-icon {
  width: 14px;
  height: 14px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-list {
  @include feature-icon;
}

.svg-circle-success {
  @include feature-icon;
}

.svg-circle-success-gray {
  @include feature-icon;
}

.svg-play-circle {
  width: 27px;
  height: 27px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-tick {
  @include feature-icon;
}

.svg-tick-green {
  @include feature-icon;
}

.svg-musical-note {
  @include feature-icon;
}

.svg-world {
  @include feature-icon;
}

.svg-calendar {
  @include feature-icon;
}

.svg-time {
  @include feature-icon;
}

.svg-certificate {
  @include feature-icon;
}

.svg-subtitle {
  @include feature-icon;
}

.svg-share {
  @include feature-icon;
}

.svg-in-progress {
  @include feature-icon;
}

.svg-three-dots {
  @include feature-icon;
}

.svg-share-video {
  @include feature-icon;
}

.svg-carousel-icon {
  width: 39px;
  height: 23px;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-list-icon {
  width: 33px;
  height: 23px;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-grid-icon {
  width: 34px;
  height: 23px;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-right-arrow {
  width: 18px;
  height: 18px;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-left-arrow {
  width: 18px;
  height: 18px;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-thumbs-up {
  width: 40px;
  height: 40px;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.svg-thumbs-down {
  width: 40px;
  height: 40px;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
