@import "@styles/abstracts";

.download {
  $size: 26px;
  $background: $secondary-color;

  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $background;
  color: $color-white;
  padding-top: 8px;
  @include transition(background-color);

  &:hover {
    background-color: darken($background, 15%);
  }
}
.icon {
  height: 13px;
  overflow: hidden;
}
