@import "@styles/abstracts";

.active {
  color: $color-seagreen;
}
.pending {
  color: $main-color;
}
.status {
  text-transform: uppercase;
  font-weight: 700;
}
