.info {
  display: flex;
  align-items: flex-start;

  &-legend {
    flex-grow: 1;
  }
  &-report {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    margin-left: 5px;
  }
  &-icon {
    $size: 18px;

    width: $size;
    height: $size;
    margin-right: 5px;
  }
}
