@import "@styles/abstracts";

.learnPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .heading {
    font-size: 14px;
  }

  .description {
    font-size: 12px;
    color: $color-black;
    margin-top: 6px;
  }

  .headerLayoutFilters {
    display: flex;
    align-items: center;
    grid-gap: $grid-gap-medium;

    .layoutIcon svg {
      color: $color-steelblue;
    }

    .activeIcon svg {
      color: $color-midnightblue;
    }
  }

  .addResourceBtn {
    width: auto;
    white-space: nowrap;
  }
}

.searchBtn {
  width: 100%;
}

.topFilters {
  display: flex;
  align-items: center;
  grid-gap: $grid-gap;
  justify-content: space-between;
  margin-top: 20px;
}

// SEARCH AND FILTERS

.searchAndFilters {
  position: relative;
  z-index: 10;
  padding: $grid-gap-small;
  border-radius: 8px;
  background: $color-whitesmoke;
  margin-top: 20px;

  .inputWithFiltersList {
    align-items: center;
  }

  .filtersInput {
    width: 100%;
    background: $color-white;
    font-size: 14px;
    padding: 8px 1rem;
    border: 1px solid $color-silver;
    border-radius: 3px;
  }

  .searchType {
    font-size: 14px;
    cursor: pointer;
    text-align: center;
  }
}

.resourcesAndSortBy {
  @include flex-between;
  margin: $grid-gap-medium 0;
  font-family: $font-main;

  .heading {
    color: $color-gray;
    font-size: 14px;
  }

  .sortBy {
    font-size: 16px;
    color: $color-gray;

    cursor: pointer;

    span {
      font-weight: bold;
      color: $color-black;
    }
  }

  .sortByOptions {
    display: inline-block;
  }
}
.sortBy {
  position: relative;
  z-index: 20;
}
.carousels {
  margin: $grid-gap-large 0;
  display: flex;
  flex-direction: column;
  grid-gap: $grid-gap-large;

  &-icon {
    font-size: 23px;

    &-active {
      color: $color-midnightblue;
    }
  }
}

.hidden {
  display: none !important;
}

.gridItem {
  padding: 6px 6px 6px;
}

.gridCourseCard {
  max-width: 211px;
  width: 100%;
  height: 100%;
  background: $color-whitesmoke;
  //box-shadow: 0 10px 30px rgba(22, 58, 95, 0.05);

  //border-radius: 4px;
  overflow: hidden;
}

.tilesLayout {
  display: grid;
  grid-gap: $grid-gap-small;
}

.favouriteHeadingWithIcon {
  cursor: pointer;
  display: flex;
  grid-gap: $grid-gap-small;
  align-items: center;

  .heartIcon {
    @include flexCenter;
    transform: scale(1.3);
  }
}

.favouritePageHeadingWithIcon {
  display: flex;
  grid-gap: $grid-gap-small;
  justify-content: flex-start;
  align-items: center;

  .heartIcon {
    @include flexCenter;
    transform: scale(1.3);
  }
}

.description {
  font-size: 12px;
  color: $color-black;
  margin-top: 6px;
}

.backToMainView {
  text-align: right;
  cursor: pointer;
}

//    ******************* Pagination Styles *********************      //

.paginationContainer {
  display: flex;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.pageLinkClassName,
.prevBtn,
.nextBtn {
  background: $color-gainsboro;
  font-size: 14px;
  width: 29px;
  height: 29px;
  @include flexCenter;
  cursor: pointer;
}

.activeLinkClassName {
  background: $color-steelblue;
  color: $color-white;
}
.noResources {
  font-size: 18px;
  line-height: 1.4;
  margin: 10px auto;

  &Link {
    $color: $color-lightorange;

    color: $color;
    cursor: pointer;
    @include transition(color);

    &:hover {
      color: darken($color, 15);
    }
  }
}
