@import "@styles/abstracts";

.text {
  margin-bottom: 20px;
}
.alert {
  &-email {
    color: $secondary-color;
  }
  &-button {
    margin-top: 25px;
    margin-left: auto;
  }
}
