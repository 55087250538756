@import "@styles/abstracts";

.block {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: $color-lightgray;
}
.switcher {
  margin-right: 10px;
}
