@import "@styles/abstracts/";

.header {
  position: relative;
  flex-shrink: 0;
  border-bottom: 1px solid;
  background: $color-white;
  border-color: $main-color;

  @include media-breakpoint-down(md) {
    & > [class*="menu-line"] {
      right: -100%;
      transition: right 0.6s cubic-bezier(0.16, 0.16, 0.4, 0.82) 0.3s;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100vw;
        height: 100%;
        opacity: 0;
        background-color: rgba($color-midnightblue, 0.8);
        transform: translateX(-100%);
        @include transition(opacity);
      }
    }

    &.show-menu {
      & > [class*="menu-line"] {
        right: 0;
        @include transition(right);

        &:after {
          opacity: 1;
          z-index: 25;
          transition: opacity $transition-main 0.3s;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    position: static;
    border-bottom: none;
  }
}

.menu-line {
  $paddingY: 20px;

  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 30;
  width: 250px;
  min-height: 100vh;
  background: $main-color;
  color: $color-white;
  transform: translateY(100%);
  padding-top: $paddingY;
  padding-bottom: $paddingY;

  @include media-breakpoint-up(md) {
    $paddingY: 0;

    position: static;
    min-height: 30px;
    width: auto;
    transform: none;
    padding-top: $paddingY;
    padding-bottom: $paddingY;
  }
}
