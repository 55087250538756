@import "@styles/abstracts";

.titleline {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-name {
    color: $secondary-color;
  }
  &-button {
    width: 142px;
  }
}
