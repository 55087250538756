@import "@styles/abstracts";

@mixin labelValue {
  font-size: 44px;
  font-weight: 900;
  line-height: 50px;
  margin-right: 15px;
}

.section {
  $paddingY: 20px;

  &:not(:first-child) {
    padding-top: $paddingY;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $color-gainsboro;
    padding-bottom: $paddingY;
  }
}
.category {
  text-transform: uppercase;
}
.description {
  font-size: 12px;
  line-height: 16px;
  color: $color-midnightblue;
}
.top-line {
  display: flex;
  justify-content: space-between;

  &-info {
    flex-grow: 1;
    margin-right: 15px;
  }
  &-legend {
    flex-shrink: 0;
    font-size: 12px;
    line-height: 16px;
  }
  &-self {
    color: $color-lightorange;
  }
}
.marks {
  display: flex;
  align-items: center;

  &-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 70px;
    }
  }
  &-indicator {
    $size: 15px;
    $color: $color-lightgray;

    display: flex;
    align-items: center;
    justify-content: center;
    height: $size;
    width: $size;
    border-radius: 50%;
    border: 1px solid $color;
    background-color: $color-lightgray;
    margin-right: 15px;
    @include transition(border-color);

    .marks-item:hover & {
      border-color: $color-cornflowerblue;
    }

    &:after {
      $size: 7px;

      content: "";
      display: block;
      width: $size;
      height: $size;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      z-index: -1;
      background-color: $color-gray;
      @include transition(opacity);
    }

    &-active:after {
      opacity: 1;
      z-index: 10;
      pointer-events: auto;
    }
  }
  &-value {
    position: relative;
    @include labelValue();

    &-manager {
      color: $color-cornflowerblue;
    }
    &-self {
      color: $color-lightorange;
    }
  }
  &-description {
    width: 130px;
    font-size: 14px;
    line-height: 19px;
    color: $color-midnightblue;
  }

  @mixin havingAssessment {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      height: 2px;
    }
  }
  &-with-self {
    @include havingAssessment();
    &:after {
      background-color: $color-lightorange;
    }
  }
  &-with-manager {
    @include havingAssessment();
    &:after {
      background-color: $color-cornflowerblue;
    }
  }
}
.metrics {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 19px;
  color: $color-midnightblue;
  margin-bottom: -22px;

  &-section {
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }

  &-wrapper {
    background-color: $color-superlightgrey;
    padding: 15px 20px;
    margin-top: 15px;
  }

  &-item {
    margin-bottom: 22px;
  }
  &-title {
    font-weight: 800;
  }
}
.previous {
  display: flex;

  &-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 125px;
    }
  }
  &-value {
    @include labelValue();

    &-self {
      color: $color-lightorange;
    }
    &-manager {
      color: $color-cornflowerblue;
    }
  }
  &-text {
    font-size: 14px;
    line-height: 19px;
    color: $color-midnightblue;
  }
}
