@import "@styles/abstracts";

.pageHeader {
  padding-top: 0;
  padding-bottom: 15px;
}
.top-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  column-gap: 35px;
  &-group {
    display: flex;
    align-items: center;
    column-gap: 2px;
  }
}
.radiusLeft {
  border-radius: 33px 0 0 33px !important;
}
.radiusRight {
  border-radius: 0 33px 33px 0 !important;
}
.button {
  max-width: 125px !important;

  &-quantity {
    $size: 15px;

    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    border-radius: 50%;
    overflow: hidden;
    font-size: 0.8em;
    background: $color-white;
    margin-left: 5px;

    [class*="orange"] & {
      color: $secondary-color;
    }

    [class*="gray"] & {
      color: $color-gainsboro;
    }
  }
}

.filter {
  display: flex;
  align-items: center;
  column-gap: 20px;
  h3 {
    margin-bottom: 0;
    font-weight: normal;
  }
}

.noData {
  display: flex;
  flex-direction: column;
  span {
    color: $color-darkgray;
  }
}
