@import "@styles/abstracts";

.badge {
  padding: 4px 6px;
  font-size: 10px;
  border-radius: 10px;
}

.beginner {
  background: rgba(5, 166, 157, 0.1);
  color: $color-lightseagreen;
}

.intermediate {
  background: rgba(75, 168, 221, 0.1);
  color: $color-cornflowerblue;
}

.advanced {
  background: rgba(255, 140, 0, 0.1);
  color: $color-orangered;
}
