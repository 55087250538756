@import "@styles/abstracts";

.menu-wrapper {
  width: fit-content;
}

.calendar-wrapper {
  min-width: 300px;
  width: 100%;
}

.select-wrapper {
  min-width: 143px;
  flex: 0 1;
}

.dropdown-indicator {
  width: 30px;
  height: 8px;
}
