@import "@styles/abstracts";

.block {
  display: flex;
  align-items: center;
}
.switcher {
  $borderColor: $color-lighttelegrey;
  $switcherHeight: 21px;
  $switcherPadding: 1px;

  flex-shrink: 0;
  position: relative;
  height: $switcherHeight;
  width: 36px;
  border: 1px solid $borderColor;
  border-radius: 30px;
  cursor: pointer;
  margin-left: 9px;

  @include transition(background-color, border-color);

  &:after {
    $size: $switcherHeight - ($switcherPadding * 4);

    content: "";
    position: absolute;
    top: $switcherPadding;
    z-index: 10;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $color-white;

    @include transition(left, transform);
  }

  &:hover {
    border-color: darken($borderColor, 15);
  }

  &-active {
    background-color: $color-seagreen;

    &:after {
      left: -$switcherPadding;
      transform: translateX(100%);
    }
  }
  &-inactive {
    background-color: darken($borderColor, 10);

    &:after {
      left: $switcherPadding;
      transform: translateX(0);
    }
  }
  &-banned {
    cursor: not-allowed;
  }
}
.text {
  width: 142px;
  font-size: 14px;
  line-height: 16px;
  color: $color-darkgray;
}
.label {
  font-weight: 800;
  @include transition(color);

  &-active {
    color: $color-seagreen;
  }
  &-inactive {
    color: $color-firebrick;
  }
}
