@import "@styles/abstracts";

.scope-text {
  font-size: 14px;
  line-height: 19px;
  color: $color-black;
}
.responsibilities {
  &-title {
    font-weight: bold;
    margin-bottom: 19px;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
