@import "@styles/abstracts";

.block {
  display: flex;
  align-items: center;
  color: $main-color;
}
.name {
  margin-right: 15px;
}
.info {
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 3px;
  font-size: 12px;
  line-height: 14px;
  background-color: $color-whitesmoke;
}
.role {
  $paddingX: 4px;

  padding-right: $paddingX;
  padding-left: $paddingX;
}
.status {
  $paddingX: 4px;

  text-transform: uppercase;
  color: $color-white;
  padding-right: $paddingX;
  padding-left: $paddingX;
}
.active {
  background-color: $color-seagreen;
}
.inactive {
  background-color: $secondary-color;
}
.pending {
  background-color: $color-cornflowerblue;
}
.deleted {
  background-color: $color-firebrick;
}
