@import "@styles/abstracts";

.circle-success {
  color: transparent;
}

.green {
  color: $color-lightseagreen;
}

.gray {
  color: $color-gray;
}
