@import "@styles/abstracts";

.empty {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  background: transparent;
}
.short {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  max-width: 700px;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;

  & > [class*="content"] {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @include adaptiveDefaultGap(padding);

    @include media-breakpoint-up(xs) {
      $paddingX: 25px;

      padding-top: 40px;
      padding-right: $paddingX;
      padding-bottom: 20px;
      padding-left: $paddingX;
    }
  }
}
