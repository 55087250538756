@import "@styles/abstracts";

$paddingX: 12px;

.item {
  $paddingY: 12px;

  width: 100%;
  border-radius: 4px;
  background-color: $color-gainsboro;
  color: $color-royalblue;
  padding-top: $paddingY;
  padding-bottom: $paddingY;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.info {
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
  margin-bottom: 14px;

  &-metrics {
    flex-grow: 1;
    display: flex;
  }
  &-title {
    width: 152px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 14px;
  }
  &-status {
    display: flex;
    align-items: center;
  }

  &-icon {
    $size: 7px;

    flex-shrink: 0;
    display: inline-flex;
    width: $size;
    height: $size;
    margin-right: 9px;

    &:before {
      content: "";
      border: $size / 2 solid transparent;
      border-left: $size solid $color-gray;
      border-radius: 0;
      transform-origin: center;
      margin-top: 0;
      @include transition(transform, margin-top);
    }
    .expand:before {
      margin-top: 2px;
      transform: rotate(90deg);
    }
  }
}
.top {
  display: flex;
  justify-content: space-between;
  padding-right: $paddingX;
  padding-left: $paddingX;
}
.metrics {
  width: 100%;
  font-size: 14px;
  background: $color-superlightgrey;
  color: $color-midnightblue;
  padding: 15px $paddingX;
  margin-top: 6px;

  &-title {
    font-weight: 800;
  }
  &-wrapper {
    margin-top: -10px;
  }
  &-item {
    margin-top: 10px;
  }
}
.weight {
  flex-shrink: 0;
  display: flex;
  align-items: center;

  &-title {
    margin-right: 8px;
  }
  &-value {
    display: flex;
    align-items: center;
    width: 50px;
    height: 27px;
    border: 1px solid $color-lightgray;
    border-radius: 4px;
    font-size: 14px;
    font-style: italic;
    line-height: 19px;
    color: $color-darkgray;
    background-color: $color-white;
    padding-left: 8px;
  }
}

.calendar {
  display: flex;
  justify-content: space-between;
  padding-right: $paddingX;
  padding-left: $paddingX;
}
.month {
  width: 8%;

  &-name {
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    color: $color-royalblue;
    margin-bottom: 2px;
  }
  &-block {
    display: flex;
    align-items: center;
    height: 26px;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    padding-left: 9px;
  }
  &-disabled {
    background-color: $color-lightgray;
  }
  &-active {
    background-color: $color-white;
  }
  &-current {
    background-color: $color-cornflowerblue;
  }
  &-next {
    background-color: $color-lightorange;
  }
}
.value {
  &-separator {
    $marginX: 5px;

    display: inline-flex;
    color: $color-lightorange;
    margin-right: $marginX;
    margin-left: $marginX;
  }
}
