@import "@styles/abstracts";

.row {
  $paddingX: 15px;

  padding-right: $paddingX;
  padding-left: $paddingX;
}
.body {
  .row {
    border: 1px solid transparent;
    border-radius: 3px;

    &:not(:last-child):not(.row-expanded) {
      margin-bottom: 10px;
    }

    &-expanded {
      border-bottom-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
      margin-bottom: -1px;
    }

    &-active,
    &-active + .details-row {
      border-color: $secondary-color;
    }
    &-pending,
    &-pending + .details-row {
      border-color: $main-color;
    }
    &-deactivated,
    &-deactivated + .details-row {
      border-color: $color-graphitegray;
    }
    &-deleted,
    &-deleted + .details-row {
      border-color: $color-pureedpumpkin;
    }
  }
  .details-row {
    $paddingX: 15px;

    border: 1px solid orange;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-right: $paddingX;
    padding-left: $paddingX;
  }
}
