@import "@styles/abstracts";

.wrapper {
  margin-bottom: 25px;

  @include media-breakpoint-up(xs) {
    margin-bottom: 45px;
  }
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-breakpoint-up(xs) {
    flex-wrap: nowrap;
  }
}

@include media-breakpoint-down(xs) {
  .button {
    width: 100%;

    &-cancel {
      margin-bottom: 10px;
    }
  }
}
