@import "@styles/abstracts";

.course-desc {
  color: $color-black;
  margin-top: -8px;
}

.top-details-container {
  padding: $grid-gap-medium 0;
}

.top-details {
  //@include card;

  .logo-and-actions {
    @include flex-between;

    .addToListBtn {
      color: $color-gray;
    }

    .logo {
      max-width: 160px;
      height: auto;
    }

    .actions {
      display: flex;
      align-items: center;
      grid-gap: $grid-gap-small;

      .bookmark {
        display: flex;
        align-items: center;
        grid-gap: $grid-gap-small;
        cursor: pointer;
      }

      .bookmark-number {
        color: $color-gray;
      }

      .circle-success {
        color: transparent;
      }

      .heartIcon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .title {
    color: $color-midnightblue;
    margin: $grid-gap-small 0;
    font-size: 30px;
    line-height: 1.25;
    font-weight: normal;
  }

  .course-source {
    //color: $color-gray;
    font-size: 12px;
    margin-bottom: 6px;
  }
}

.course-overview {
  //@include card;
  border-top: 1px solid $color-gainsboro;
  padding-top: $grid-gap-small;
  margin-top: $grid-gap-medium;

  padding-bottom: $grid-gap-medium;
  margin-bottom: $grid-gap-medium;

  border-bottom: 1px solid $color-gainsboro;

  .title {
    color: $color-midnightblue;
    margin: $grid-gap-small 0 $grid-gap-small;
  }

  .description {
    color: $color-midnightblue;
    font-size: 18px;
  }

  .arrowDown {
    color: transparent;
  }

  .read-more-overview {
    margin: $grid-gap-small 0;
  }

  .read-more-syllabus {
    margin: $grid-gap-medium 0 $grid-gap-small;
    color: $color-midnightblue;
    cursor: pointer;
  }

  .heading {
    color: $color-midnightblue;
    margin-top: $grid-gap-large;
    margin-bottom: $grid-gap-small;
  }

  .syllabus-heading {
    margin-bottom: $grid-gap-small;
  }

  .syllabus-details {
    display: flex;
    flex-direction: column;
    grid-gap: $grid-gap-small;
    color: $color-midnightblue;
  }

  .instructor {
    color: $color-midnightblue;
    font-size: 16px;
    margin: 0;
  }

  .tagsContainer {
    display: flex;
    align-items: center;
    grid-gap: $grid-gap-small;
  }

  .tag {
    width: auto;
    color: $color-gray;
    font-weight: normal;
    background: $color-lavender;
  }
}

.course-notes {
  margin-top: 2rem;
  padding-bottom: 2rem;

  .heading {
    font-size: 1.3rem;
    font-weight: bold;
    color: $color-cornflowerblue;
  }

  .heading-2 {
    font-size: 1.1rem;
  }

  .keep-track__desc {
    margin-top: 1rem;
  }

  .personal-checklist__heading {
    margin-top: 1.5rem;
  }

  .checkbox-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-gap: $grid-gap-small;
    margin-top: $grid-gap-medium;
  }

  .notes-heading {
    margin-bottom: 1.5rem;
  }

  .notes-input {
    height: 100%;
    //margin: 1.5rem 0;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    padding: 1rem;
  }
}

.course-details-card {
  background: $color-lavender;

  .image-container {
    position: relative;

    .image {
      width: 100%;
      object-fit: cover;
    }

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .play-circle {
      cursor: pointer;
    }
  }

  .course-details {
    padding: $grid-gap-small;
  }

  .courseBtn {
    width: 100%;
    margin: $grid-gap-small 0px $grid-gap-medium;
    border-color: $color-darkorange;
  }

  .features {
    display: flex;
    flex-direction: column;
    grid-gap: $grid-gap-small;
  }

  .course-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $color-darkgray;
    padding: $grid-gap-small;

    grid-gap: $grid-gap-small;
    margin-top: 4px;

    .title {
      font-size: 1rem;
      font-weight: 600;
      color: $color-midnightblue;
    }
  }

  .course-bottom {
    padding: $grid-gap-small;

    .quizBtn {
      border-color: $color-royalblue;
      width: 100%;
      margin: $grid-gap-small 0px $grid-gap-small;
    }

    .bottom-details {
      text-align: center;
      padding-bottom: $grid-gap-small;
    }
  }
}
.editButton {
  width: 80px;
}
