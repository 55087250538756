@import "./variables";
@import "./colors";

@mixin trbl($arguments...) {
  @if (length($arguments) == 1) {
    top: $arguments;
    right: $arguments;
    bottom: $arguments;
    left: $arguments;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin plusIcon($color, $width: 3px, $size: 0.8128em) {
  flex-shrink: 0;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 3px;
    background-color: $color;
    @include transition(opacity, background-color);
  }

  &:before {
    top: 0;
    left: 50%;
    height: $size;
    width: $width;
    transform: translateX(-50%);
  }
  &:after {
    top: 50%;
    left: 0;
    height: $width;
    width: $size;
    transform: translateY(-50%);
  }
}

@mixin media-breakpoint-down($breakpoint, $breakpoints: $media-sizes) {
  @if index(map-keys($breakpoints), $breakpoint) {
    $normalizedBreakpoint: map-get($breakpoints, $breakpoint);
    @media (max-width: $normalizedBreakpoint) {
      @content;
    }
  } @else {
    @error "Значение #{$breakpoint} должно находиться в массиве!";
  }
}

@mixin media-breakpoint-up($breakpoint, $breakpoints: $media-sizes) {
  @if index(map-keys($breakpoints), $breakpoint) {
    $normalizedBreakpoint: map-get($breakpoints, $breakpoint);
    @media (min-width: $normalizedBreakpoint + 1) {
      @content;
    }
  } @else {
    @error "Значение #{$breakpoint} должно находиться в массиве!";
  }
}

@mixin media-breakpoint-between($breakpointStart, $breakpointEnd, $breakpoints: $media-sizes) {
  @if not(index(map-keys($breakpoints), $breakpointEnd)) {
    @error "Значение #{$breakpointStart} должно находиться в массиве!";
  } @else if not(index(map-keys($breakpoints), $breakpointEnd)) {
    @error "Значение #{$breakpointEnd} должно находиться в массиве!";
  } @else {
    $normalizedBreakpointStart: map-get($breakpoints, $breakpointStart);
    $normalizedBreakpointEnd: map-get($breakpoints, $breakpointEnd);

    @if ($normalizedBreakpointStart >= $normalizedBreakpointEnd) {
      @error "Стартовая точка ограничения должна быть меньше конечной!";
    } @else {
      @media (min-width: $normalizedBreakpointStart + 1) and (max-width: $normalizedBreakpointEnd) {
        @content;
      }
    }
  }
}

@mixin transition($transitions...) {
  $transition_result: ();
  @each $transition in $transitions {
    $transition_result: append($transition_result, $transition $transition-main, comma);
  }
  transition: $transition_result;
}

@mixin reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  text-align: left;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@mixin adaptiveDefaultGap($negative, $margins...) {
  @if $negative {
    $negative: "-";
  } @else {
    $negative: "";
  }

  @each $margin in $margins {
    #{$margin}: #{$negative}#{$grid-gap-small};

    @include media-breakpoint-up(xxxs) {
      #{$margin}: #{$negative}$grid-gap;
    }
  }
}

@mixin free-tag {
  padding: 6px 1.5rem;
  border-radius: 30px;
  font-size: 10px;
  color: $color-white;
  cursor: pointer;
  background: $color-darkorange;
}

@mixin flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin card {
  padding: $grid-gap-medium;
  border-radius: 15px;
  background: $color-white;
  border: 1px solid $color-gainsboro;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
