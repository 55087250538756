@import "@styles/abstracts";

.item {
  $paddingX: 3px;

  padding-right: $paddingX;
  padding-left: $paddingX;

  &-status {
    width: 8%;
  }
  &-name {
    width: 15%;
  }
  &-reports {
    width: 9%;
  }
  &-role {
    width: 16%;
  }
  &-industry {
    width: 7%;
  }
  &-company {
    width: 6%;
  }
  &-employed {
    width: 13%;
  }
  &-expertise {
    width: 8%;
  }
  &-gap {
    width: 12%;
  }
  &-report {
    width: 6%;
  }
}
.cell {
  font-size: 12px;
  line-height: 14px;
}
