@import "@styles/abstracts";

.video {
  //margin-top: $grid-gap-medium;
}

.modal-bottom-first-row {
  background: $color-gainsboro;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .first-row-left {
    display: flex;
    align-items: center;
    grid-gap: $grid-gap-small;
    font-size: 12px;
    padding: 4px $grid-gap-small;
  }
}
.video-completed {
  cursor: default;
}
.modal-bottom-second-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $grid-gap-small $grid-gap-small;

  .second-row-left {
    display: flex;
    align-items: center;
    grid-gap: $grid-gap-medium;
    font-size: 12px;
    padding: 4px $grid-gap-small;

    .logo-with-views {
      display: flex;
      align-items: center;
      grid-gap: $grid-gap-small;

      .logo {
        max-width: 20px;
      }
    }
  }

  .second-row-right {
    font-size: 12px;
    display: flex;
    align-items: center;
    grid-gap: $grid-gap-medium;
  }

  .second-row-right {
  }

  .bold {
    font-weight: bold;
  }
}

//  utility classes
.bold {
  font-weight: bold;
}

.small {
  font-size: 10px;
}

.mt-sm {
  margin-top: 4px;
}

.medium {
  font-size: 12px;
}
