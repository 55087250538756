@import "@styles/abstracts";

$lineHeight: 3px;
$borderRadius: 3px;

.progress {
  position: relative;
  width: 100%;
  line-height: 1;
  font-weight: 600;

  &-default {
    color: $color-darkslategray;

    & .line {
      background-color: $color-silver;
    }
    & .separator {
      background: $color-midnightblue;
    }
  }
  &-white {
    color: $color-white;

    & .line {
      background-color: $color-white;
    }

    & .separator {
      background: $color-darkorange;
    }
  }
  &-small {
    font-size: 12px;
  }
  &-normal {
    font-size: 16px;
  }
}
.line {
  position: absolute;
  bottom: -3px;
  left: 0;
  height: $lineHeight;
  width: 100%;
  border-radius: $borderRadius;
  transform: translateY(100%);
}
.separator {
  position: absolute;
  top: 50%;
  left: 50%;
  height: $lineHeight + 4;
  width: 1px;
  transform: translate(-50%, -50%);
}
.part {
  position: absolute;
  z-index: 10;
  width: 50%;
  height: 100%;

  &-negative {
    left: 0;
    transform: rotateZ(180deg);
  }
  &-positive {
    left: 50%;
  }
}
.filler {
  height: 100%;
  @include transition(background-color);
  border-top-right-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;

  &-great {
    background-color: $color-lightseagreen;
  }
  &-good {
    background-color: $color-cornflowerblue;
  }
  &-bad {
    background-color: $color-darkorange;
  }
  &-terrible {
    background-color: $color-pureedpumpkin;
  }
}
