@import "@styles/abstracts/";

.dropdown-indicator {
  width: 30px;
  height: 8px;
}

.selector-wrapper {
  min-width: 143px;
  flex: 0 1;
}
