@import "@styles/abstracts";

.strong {
  color: $main-color;
}
.details-row {
  $marginX: 30px;

  margin-left: $marginX;
  margin-right: $marginX;
}
