@import "@styles/abstracts";

.resource {
  background: $color-lavender;

  display: grid;
  grid-template-rows: auto 1fr;

  .author-logo {
    //max-height: 40px;
    width: 100%;
    max-height: 110px;
    height: 120px;
    //width: auto;
  }

  .course-details {
    padding: $grid-gap-small;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .course-title {
    font-size: 1rem;
    color: $color-black;
    //margin-top: $grid-gap-small;
    margin-bottom: $grid-gap-large;
    font-weight: 600;
  }

  .rating-bookmark-container {
    @include flex-between;

    .rating {
      display: flex;
      align-items: center;
      grid-gap: $grid-gap-small;
    }

    .rating-number {
      color: $color-gray;
    }

    .bookmark {
      cursor: pointer;
    }
  }
}
