@import "@styles/abstracts/";

body {
  font-family: $font-main;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  color: $main-color;

  @include media-breakpoint-up(xs) {
    font-size: 16px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include adaptiveDefaultGap(false, padding-top, padding-bottom);
}
