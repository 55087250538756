.rc-slider {
  display: flex;
  align-items: center;
  padding: 0;

  &.slider-primary {
    .rc-slider-rail {
      background: #d9d9d9;
    }
    .rc-slider-track,
    .rc-slider-handle {
      @apply bg-primary;
      @apply text-white;
    }
  }

  &.size-sm {
    .rc-slider-rail,
    .rc-slider-track {
      height: 5px;
    }
    .rc-slider-handle {
      width: 18px;
      height: 18px;
    }
  }

  &.rounded-none {
    .rc-slider-rail,
    .rc-slider-track {
      border-radius: 0;
    }
  }

  &.show-value {
    .rc-slider-handle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;

      &::after {
        content: attr(aria-valuenow);
        display: block;
      }
    }
  }
}

.radio-gray {
  span {
    color: #898989 !important;
    border-color: #898989 !important;

    &::after {
      background-color: #898989 !important;
    }
  }

  &.highlight {
    span {
      &::after {
        background-color: $color-white !important;
        border: 3px solid $color-red;
        opacity: 1 !important;
        z-index: 10 !important;
      }
    }
  }
}

.checkbox-gray {
  span {
    color: #898989 !important;
    border-color: #898989 !important;
  }
  
  &.highlight {
    position: relative;

    .overlay {
      position: absolute;
      background-color: $color-red;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      border-radius: 1px;  
    }
  }
}

.react-datepicker-wrapper {
  input {
    height: 33px;
    line-height: 33px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    transition: border-color 0.3s cubic-bezier(0.16, 0.16, 0.4, 0.82);
  }
}

.ck-editor {
  ul {
    list-style: disc;
    margin-left: 1rem;

    ul {
      list-style: circle;

      ul {
        list-style: square;
      }
    }
  }

  ol {
    list-style: decimal;
    margin-left: 1rem;

    ol {
      list-style: upper-alpha;

      ol {
        list-style: lower-alpha;
      }
    }
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  a {
    color: #49a7dd;
    text-decoration: underline;
  }
}
