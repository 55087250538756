@import "@styles/abstracts";

.related-learning-resources {
  //@include card;

  margin-top: $grid-gap-medium;

  .title {
    color: $color-midnightblue;
    margin: 0 0 $grid-gap-small;
  }

  .resources {
    margin-top: $grid-gap-small;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: $grid-gap-small;
  }

  .gridCourseCard {
    max-width: 211px;
    width: 100%;
    height: 100%;
    background: $color-whitesmoke;
    //box-shadow: 0 10px 30px rgba(22, 58, 95, 0.05);

    //border-radius: 4px;
    overflow: hidden;
  }
}
