@import "@styles/abstracts";

.expand {
  $color: $main-color;
  $iconColor: $secondary-color;

  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: inherit;

  @include transition(color);

  &:hover {
    color: darken($color, 15);

    .expand-icon {
      &:before,
      &:after {
        background-color: darken($iconColor, 15%);
      }
    }
  }

  &-icon {
    $size: 0.8128em;
    $width: 3px;

    flex-shrink: 0;
    position: relative;
    width: $size;
    height: $size;
    margin-right: 17px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 3px;
      background-color: $iconColor;
      @include transition(opacity, background-color);
    }

    &:before {
      top: 0;
      left: 50%;
      height: $size;
      width: $width;
      transform: translateX(-50%);
    }
    &:after {
      top: 50%;
      left: 0;
      height: $width;
      width: $size;
      transform: translateY(-50%);
    }
  }
  &-hide:before {
    opacity: 0;
  }
}
