@import "@styles/abstracts";

.role {
  cursor: pointer;
}
.name {
  @include transition(color);

  &:hover {
    color: $color-darkorange;
  }
}
