@import "@styles/abstracts";

.form-wrapper {
  margin-bottom: 18px;
}
.button {
  @include media-breakpoint-up(xxs) {
    margin-left: auto;
  }
  @include media-breakpoint-down(xxs) {
    width: 100%;
  }
}
