@import "@styles/abstracts";

.intro {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &-info {
    flex-grow: 1;
  }
  &-reports {
    flex-shrink: 0;
  }
}
.wrapper {
  display: flex;
}
.button {
  flex-shrink: 0;
  width: auto;
  margin-left: 10px;
}
.link {
  $color: $color-steelblue;

  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: $color;

  &-icon {
    $size: 12px;

    width: $size;
    height: $size;
    cursor: pointer;
    margin-left: 5px;
    @include transition(color);

    &:hover {
      color: darken($color, 15);
    }
  }
}
