@import "@styles/abstracts";

@mixin cardTitle {
  padding: $grid-gap-small;
  font-size: 12px;
  color: $color-darkgray;
}

.tileCardsContainer {
  .tileCards {
    margin: 0;
    margin-bottom: $grid-gap-medium;
    border-bottom: 1px solid #d8d8d8;

    .favourite {
      @include cardTitle;
    }

    .name {
      @include cardTitle;
    }

    .rating {
      @include cardTitle;
      text-align: center;
    }

    .duration {
      @include cardTitle;
      text-align: center;
    }

    .levenAndPrice {
      @include cardTitle;
      display: flex;
      justify-content: space-between;
    }
  }
  .cards {
    display: grid;
    grid-gap: $grid-gap-small;
  }
}
