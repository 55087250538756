@import "@styles/abstracts/";

.body {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}
.cell {
  padding-top: 3px;
  padding-bottom: 2px;
}
.row {
  border-bottom: none;
  margin-bottom: 1px;

  & [class*="progress-cell"] {
    bottom: 0px;
  }

  & [class*="expand"] {
    &:hover {
      &-icon:before {
        background-color: transparent;
      }
    }
    & [class*="expand-icon"] {
      $size: 7px;

      width: $size;
      height: $size;
      margin-right: 6px;

      &:before {
        top: 0px;
        left: 0;
        width: 0;
        height: 0;
        border: $size / 2 solid transparent;
        border-left: $size solid $secondary-color;
        border-radius: 0;
        background-color: transparent;
        transform: none;
        transform-origin: center;
        @include transition(transform, top);
      }
      &:after {
        display: none;
      }
    }
    & [class*="expand-hide"]:before {
      top: 2px;
      opacity: 1;
      transform: rotate(90deg);
    }
  }
  & [class*="second-level"] {
    margin-bottom: 0;
  }
}
.cell {
  $paddingX: 9px;

  &:first-child {
    padding-left: $paddingX;
  }
  &:last-child {
    padding-right: $paddingX;
  }
}
.details-row {
  $marginX: 20px;

  margin-right: $marginX;
  margin-left: $marginX;

  & .details-row {
    font-size: 12px;
    line-height: 16px;
  }
}
