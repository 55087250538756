@import "@styles/abstracts";

.wrapper {
  height: 100%;
  margin-top: -15px;
  margin-bottom: -15px;
  flex: 1;
  display: flex;
}

.page-container {
  max-width: 1200px;
  width: calc(100% - 236px);
  padding: 18px 13px;
  margin: 0;
}

.title {
  margin-left: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  letter-spacing: 1.5px;
  color: $color-cornflowerblue;
}
