@import "@styles/abstracts";

.checkbox-with-desc {
  display: flex;
  align-items: start;
  grid-gap: $grid-gap-small;
}

.checkbox-text {
  color: $color-gray;
}

.checkbox {
  margin-top: 2px;
}
