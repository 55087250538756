@import "@styles/abstracts";

.table {
  color: $color-graphitegray;
  font-size: 12px;

  &-header {
    border-bottom: 1px solid $color-gainsboro;
    padding-bottom: 20px;
  }
  &-list {
    font-size: 14px;
    font-weight: 600;
    color: $color-darkslategray;
    margin-top: 10px;
  }
  &-row {
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  @mixin items {
    position: relative;
  }

  .skill {
    @include items();
    z-index: 10;
  }
  .rating {
    @include items();
    z-index: 20;
  }
}
