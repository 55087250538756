@import "@styles/abstracts";

.modal {
  max-width: 700px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
  background: rgba($color-midnightblue, 0.8);
  @include adaptiveDefaultGap(false, padding-top, padding-bottom);
}
