@import "@styles/abstracts";

.courseCard {
  height: 100%;
  display: grid;
  grid-template-rows: 98px auto 1fr;

  &-wrapper {
    height: 100%;
  }
}

.mainImageContainer {
  position: relative;
  .mainImage {
    max-width: 211px;
    width: 100%;
    height: 98px;
    object-fit: cover;
  }

  .heartIcon {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    transform: scale(1.2);
  }
}

.courseDetails {
  padding: $grid-gap-small;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-whitesmoke;
}

.logoAndTitle {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.authorImg {
  //width: 35px;
  height: 17px;
  object-fit: cover;
  //border-radius: 50%;
}
.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 5px;
  background-color: $color-white;

  &Filler {
    position: absolute;
    height: 100%;
    background-color: $color-red;
  }
}
.videoDesc {
  background: $color-lighttelegrey;
  //margin-top: -9px;
  padding: 8px 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font: $font-nunito;
  z-index: 5;

  .iconWithTime {
    display: flex;
    align-items: center;
    grid-gap: 5px;

    .iconProgress {
      width: 12px;

      .root {
        //strokeLinecap: 'Butt',
      }

      .bg {
        fill: $color-white;
      }

      .trail {
        stroke: $color-white;
        stroke-linecap: butt;
        stroke-dasharray: 135.088px, 135.088px;
        stroke-dashoffset: 0px;
      }

      .path {
        stroke: $color-lighttelegrey;
        stroke-linecap: butt;
        stroke-dasharray: 135.088px, 135.088px;
        stroke-dashoffset: 27.0177px;
      }

      .text {
      }
    }

    .time {
    }
  }

  .videoDifficulty {
    font-weight: bold;
  }
}

.title {
  font-size: 13px;
  color: $color-darkslategray;
}

.ratingAndLabel {
  @include flex-between;
  margin-top: 4px;
}

.ratingIconAndNumber {
  display: flex;
  align-items: center;
  grid-gap: 4px;
}

.ratingNumber {
  color: $color-orangered;
  font-size: 8px;

  span {
    color: $color-gray;
  }
}

.courseTitle {
  font-size: 10px;
  margin-top: 2px;
  color: $color-darkslategray;
}

.cardFooter {
  padding: $grid-gap-medium 0 $grid-gap-small;
  font-size: 12px;

  .time {
    display: flex;
    align-items: center;
    grid-gap: 4px;

    span {
      color: $color-darkslategray;
    }
  }

  .freeBtnContainer {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: end;
    column-gap: 10px;
    align-items: center;
  }

  .freeBtn {
    width: auto;
  }
}
