@import "@styles/abstracts";

.iconProgress {
  width: 12px;
  display: flex;
  align-items: center;

  .root {
    //strokeLinecap: 'Butt',
  }

  .bg {
    fill: $color-white;
  }

  .trail {
    stroke: $color-white;
    stroke-linecap: butt;
    stroke-dasharray: 135.088px, 135.088px;
    stroke-dashoffset: 0px;
  }

  .path {
    stroke: $color-lighttelegrey;
    stroke-linecap: butt;
    stroke-dasharray: 135.088px, 135.088px;
    stroke-dashoffset: 27.0177px;
  }

  .text {
  }
}
