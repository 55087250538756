@import "@styles/abstracts";

// TODO: refactoring and optimize styles and styles names

.item {
  $paddingY: 8px;
  $paddingX: 11px;

  display: flex;
  align-items: flex-end;
  width: 237px;
  max-width: 100%;
  min-height: 76px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: $color-white;
  padding: $paddingY $paddingX;
  @include transition(background-color);

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &-manager {
    background-color: $color-cornflowerblue;
  }
  &-peer {
    background-color: $color-silver;
  }

  &-self {
    position: relative;
    overflow: hidden;
    background-image: $gradient-orange;
    z-index: 20;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: $gradient-orange-hover;
      z-index: 10;
      opacity: 0;
      @include transition(opacity);
    }
  }

  &-denied {
    opacity: 0.5;
  }
  &-pending {
    cursor: pointer;

    &.item-self {
      &:hover::before {
        opacity: 1;
      }
    }
  }
  &-done {
    $color: $main-color;

    border: 1px solid;
    background-color: $main-color;
    border-color: $color-cornflowerblue;
    @include transition(background-color);
  }
}

.content {
  position: relative;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.date {
  $height: 12px;

  height: $height;
  font-size: 9px;
  line-height: $height;
}

.text {
  margin-right: 15px;
}
.status {
  $size: 23px;

  flex-shrink: 0;
  width: $size;
  height: $size;
}
