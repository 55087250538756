@import "@styles/abstracts";

$closeSize: 17px;

// TODO: Move similar styles in common

.empty {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  background: transparent;
}
.complex {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  width: 982px;
  max-width: 90vw;
  max-height: 100%;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}
.close {
  $closeSize: 18px;

  position: absolute;
  top: 50%;
  right: 0;
  z-index: 10;
  width: $closeSize;
  height: $closeSize;
  overflow: hidden;
  cursor: pointer;
  transform: translateY(-50%);

  &:hover {
    &:before,
    &:after {
      background-color: $secondary-color;
    }
  }

  $pseudoMargin: 7px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -2px;
    width: 4px;
    height: $closeSize + 3;
    background-color: $color-white;
    @include transition(background-color);
  }
  &:before {
    right: $pseudoMargin;
    transform: rotate(45deg);
  }
  &:after {
    left: $pseudoMargin;
    transform: rotate(-45deg);
  }
}
.header {
  flex-shrink: 0;
  color: $color-white;
  background-color: $color-midnightblue;
  min-height: 59px;
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  padding: 15px $modalRowPaddingX 8px;

  &-top-row {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: $closeSize + 10;
  }
  &-secondary-title {
    color: $secondary-color;
  }
  &-second-row {
    //border-top: 1px dashed;
    border-color: $color-white;
    //padding-top: 7px;
    //margin-top: 7px;
  }
}
.body {
  $paddingY: 25px;

  flex-grow: 1;
  position: relative;
  background-color: $color-white;
  overflow-y: auto;
  padding-top: $paddingY;
  padding-bottom: $paddingY;
}
.footer {
  $paddingY: 22px;

  flex-shrink: 0;
  background: $color-white;
  padding-top: $paddingY;
  padding-bottom: $paddingY;
  border-top: 1px solid $color-lavender;
}
.buttons {
  &-block {
    display: flex;
    align-items: center;
  }
  &-item {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  &-right-side {
    justify-content: flex-end;
  }
}
