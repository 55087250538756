@import "@styles/abstracts";

.row {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
.symbol {
  flex-shrink: 0;
  width: 15px;
  height: 3px;
  border-top-left-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
  margin-right: 5px;
}
.your {
  background-color: $color-cornflowerblue;
}
.target {
  background-color: $secondary-color;
}
.self {
  background-color: $main-color;
}
