@import "@styles/abstracts";
@mixin gray-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $color-darkgray;
  padding: $grid-gap-small;

  grid-gap: $grid-gap-small;
  margin-top: 4px;

  .title {
    font-size: 1rem;
    font-weight: 600;
    color: $color-midnightblue;
  }
}

.top-details-container {
  margin-top: $grid-gap-large;

  .top-details {
    //@include card;
    border-bottom: 1px solid $color-gainsboro;
    padding-bottom: $grid-gap-small;

    .tag-and-actions {
      @include flexBetween;

      .title {
        color: $color-midnightblue;
        margin: $grid-gap-small 0;
        font-size: 30px;
        font-weight: normal;
      }

      .tag {
        border-radius: 160px;
        width: auto;
        padding: 8px 1rem;
        margin-bottom: $grid-gap-small;
      }

      .actions {
        display: flex;
        align-items: center;
        grid-gap: $grid-gap-small;

        .bookmark {
          display: flex;
          align-items: center;
          grid-gap: $grid-gap-small;
          cursor: pointer;
        }

        .bookmark-number {
          color: $color-gray;
        }

        .heartIcon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }

        .addToListBtn {
          color: $color-gray;
        }
      }
    }

    .top-details-bottom {
      @include flexBetween;

      .average-rating {
        font-size: 12px;
        margin-bottom: 6px;
      }

      .title {
        color: $color-midnightblue;
      }
    }
  }
}

.course-cards {
  display: flex;
  flex-direction: column;
  grid-gap: $grid-gap-medium;

  padding-top: $grid-gap-medium;
}

.related-cards-container {
  border-top: 1px solid $color-gainsboro;
  margin-top: $grid-gap-large;
}

.features {
  .features-items {
    display: flex;
    flex-direction: column;
    grid-gap: $grid-gap-small;
    width: 100%;
    padding: $grid-gap-medium $grid-gap-small $grid-gap-small;
  }
}

.course-details-card {
  //@include card;
  background: $color-lavender;

  .chart-container {
    margin-bottom: 1rem;
    padding: $grid-gap-small;
    position: relative;
  }

  .share-path {
    @include gray-card;
  }

  .rate-course {
    @include gray-card;
  }
}
