@import "@styles/abstracts/";

.head {
  &-cell {
    &-sort-mode:hover,
    &-sorted {
      color: $color-midnightblue;
    }
  }
}
.row {
  border-bottom: 1px solid $color-gainsboro;
}

.pagination {
  &-item {
    color: $color-graphitegray;

    &[class*="active"],
    &:hover {
      color: $secondary-color;
    }
  }
}
