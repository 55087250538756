// colors names

$color-white: #fff;
$color-black: #000;
$color-lavender: #e8e8e8;
$color-lavender-200: #e3f2f8;
$color-gainsboro: #e1e1e1;
$color-whitesmoke: #ececec;
$color-lightgray: #d3d3d3;
$color-lighttelegrey: #cccccc;
$color-superlightgrey: #f5f5f5;
$color-silver: #bfc0c1;
$color-darkgray: #9b9b9b;
$color-gray: #808184;
$color-graphitegray: #474a51;
$color-darkslategray: #404041;
$color-darkorange: #f6921d;
$color-orangered: #f64b1d;
$color-lightorange: #f6921e;
$color-tomato: #ee4035;
$color-pureedpumpkin: #c34121;
$color-firebrick: #b22222;
$color-red: #cb0000;
$color-steelblue: #3478bc;
$color-royalblue: #3378bc;
$color-midnightblue: #112d66;
$color-cornflowerblue: #4ba8dd;
$color-lightseagreen: #05a69d;
$color-seagreen: #2e8b57;
$color-grassgreen: #499f52;
$color-purple: #9600cb;

$color-primary: #49a7dd;
$color-success: #499f52;
$color-secondary: #a8a8a8;
$color-default: #102d66;

// gradients

$gradient-orange: linear-gradient(180deg, #fad961 0%, #f76b1c 100%);
$gradient-orange-hover: linear-gradient(180deg, #f76b1c 0%, #fad961 100%);
$gradient-secondary: linear-gradient(180deg, #fff 0%, #e1e1e1 100%);

// project colors
$main-color: $color-steelblue;
$secondary-color: $color-darkorange;
$placeholder-color: $color-graphitegray;
// $text-color: #bbbbbb;
