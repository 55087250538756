@import "@styles/abstracts";

.list {
  display: flex;
}
.item {
  $borderSize: 1px;
  $borderRadius: 3px;
  $color: $color-gainsboro;

  cursor: pointer;
  border-width: $borderSize 0 $borderSize $borderSize;
  border-style: solid;
  font-weight: 600;
  text-align: center;
  border-color: $color;
  @include transition(background-color, border-color);
  padding: 6px 10px;

  &:hover {
    background-color: $color;
  }

  &:first-child {
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
  }
  &:last-child {
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    border-right-width: $borderSize;
  }
  &-current {
    $activeColor: $secondary-color;

    border-color: $activeColor;

    & + [class*="item"] {
      border-left-color: $activeColor;
    }
  }
}
