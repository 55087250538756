@import "@styles/abstracts";

// .table {
//   color: $color-white;
// }
// .head {
//   &-cell {
//     &-sort-mode:hover,
//     &-sorted {
//       color: $color-darkorange;
//     }
//   }
// }
.cell {
  color: $color-white;
}
.row:not(.details-row) {
  background-color: rgba($color-white, 0.1);
}
