@import "@styles/abstracts/";

.wrapper {
  $padding: 10px;

  position: relative;
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0px 2px 22px 0 rgba($color-midnightblue, 0.4);
  padding: 10px;

  &:before {
    $margin: $padding - 1;

    content: "";
    position: absolute;
    top: $margin;
    right: $margin;
    bottom: $margin;
    left: $margin;
    z-index: 10;
    border: 1px solid;
    border-radius: 3px;
    border-color: $color-darkorange;
  }
}
.content {
  position: relative;
  z-index: 20;
  padding: 15px;
}
