@import "@styles/abstracts";
.fail {
  color: $main-color;
}
.success {
  color: $color-lightseagreen;
}
.message {
  margin-bottom: 20px;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
