@import "@styles/abstracts";

//    ******************* Pagination Styles *********************      //

.paginationContainer {
  display: flex;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.pageLinkClassName,
.prevBtn,
.nextBtn {
  background: $color-gainsboro;
  font-size: 14px;
  width: 29px;
  height: 29px;
  @include flexCenter;
  cursor: pointer;
}

.activeLinkClassName {
  background: $color-steelblue;
  color: $color-white;
}
.noData {
  font-size: 18px;
  line-height: 1.4;
  margin: 10px auto;

  &Link {
    $color: $color-lightorange;

    color: $color;
    cursor: pointer;
    @include transition(color);

    &:hover {
      color: darken($color, 15);
    }
  }
}
