@import "@styles/abstracts/";

$cellPaddingX: 7px;

.table-wrapper {
  margin-bottom: 7px;
}
.head {
  &-cell {
    padding-top: 8px;
    padding-right: $cellPaddingX;
    padding-left: $cellPaddingX;

    &-sorted {
      position: relative;

      & [class*="ka-icon"] {
        position: absolute;
        top: 50%;
        right: 7px;
        display: block;
        transform: translateY(-50%);

        &:before,
        &:after {
          $size: 5px;
          $borderSize: 2px;

          content: "";
          display: block;
          width: $size;
          height: $size;
          border-style: solid;
          border-width: 0 $borderSize $borderSize 0;
          @include transition(border-color);
        }

        &:before {
          transform: rotate(-135deg);
        }
        &:after {
          transform: rotate(45deg);
        }
      }
    }
  }
}
.body {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}
.cell {
  &-inner {
    padding: 8px $cellPaddingX 4px;
  }
}
