@import "@styles/abstracts";

.modal-tags-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: $grid-gap-small;
  padding: $grid-gap-small 0;

  .modal-tag {
    //border-radius: 20px;
  }
}

.tabs-list {
  //justify-content: center;
  border-top: 1px dashed $color-white;
  padding-top: $grid-gap-small;
  margin-top: $grid-gap-small;

  .tab {
    font-weight: unset;
  }
}

.video-details {
  @include flexBetween;

  margin-top: 20px;

  .item {
    display: flex;
    align-items: center;
    grid-gap: $grid-gap-small;
  }

  .free {
    @include free-tag;
  }

  .share {
    cursor: pointer;
  }

  .text {
    font-size: 1rem;
    color: $color-gray;
  }

  .heading {
    font-size: 1rem;
    color: $color-gray;
    font-weight: bold;
  }

  .views {
    span {
      color: $color-steelblue;
    }
  }

  .rating-number {
    span {
      color: $color-darkorange;
    }
  }
}

.tabs {
  align-self: flex-end;
}

.form-row {
  margin-bottom: -8px;
}

.modal-row {
  padding: 0;
}

.tabs-panel {
  padding: 0;
}

.modal-body {
  padding: 0;
}
