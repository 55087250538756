@import "@styles/abstracts";

.row {
  $marginY: 15px;

  &:not(:last-child) {
    margin-bottom: $marginY;
  }
  &:not(:nth-last-child(2)) {
    border-bottom: 1px solid $color-lighttelegrey;
    padding-bottom: $marginY;
  }
}
.main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.horizontal-row {
  flex-wrap: nowrap;
}

.industry {
  width: 112px;
}
.start {
  width: 134px;
}
.timezone-row {
  height: 100%;
  align-items: flex-end;
}
.save {
  align-self: flex-end;
}
.link {
  display: block;
  width: 169px;
  height: 55px;
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  color: $color-white;
  padding: 3px;
  @include transition(border-color);

  &-inner {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 10px;
    padding-left: 15px;
    @include transition(background-color);
  }

  &:not(:last-child) {
    margin-right: 42px;
  }
}

@mixin linkStyles($color) {
  $hoverColor: darken($color, 15);

  border-color: $color;

  & .link-inner {
    background-color: $color;
  }

  &:hover {
    border-color: $hoverColor;

    & .link-inner {
      background-color: $hoverColor;
    }
  }
}

.report {
  @include linkStyles($color-cornflowerblue);

  &-icon {
    $size: 32px;

    width: $size;
    height: $size;
    background-size: cover;
  }
}
.note {
  color: $color-gray;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
}
