@import "@styles/abstracts";

.typography {
  font-size: 12px;
  line-height: 1.2;
}

.roles {
  $paddingX: 8px;

  border-radius: 10px;
  background-color: $color-whitesmoke;
  padding: 8px $paddingX 11px $paddingX;

  &-input {
    @extend .typography;
    background-color: $color-white;
  }
  & [class*="select"] {
    @extend .typography;
  }
}

.error {
  color: $color-firebrick;
  margin-top: 15px;
}

.button {
  width: 100%;
  font-weight: normal;
  @extend .typography;

  &-item {
    align-self: flex-end;
  }
}
.grid {
  $marginX: 5px;

  &-list {
    margin-left: -$marginX;
    margin-right: -$marginX;
  }
  &-item {
    padding-right: $marginX;
    padding-left: $marginX;
  }
  &-active {
    max-width: 22%;
    flex-basis: 22%;
  }
  &-button {
    max-width: 12%;
    flex-basis: 12%;
  }
}
