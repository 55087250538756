@import "@styles/abstracts";

.page-container {
  margin: 0;
}

.title {
  margin-top: 2px;
  margin-left: 7px;
  margin-bottom: 6px;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: -0.25px;
  color: $color-cornflowerblue;
  text-transform: capitalize;
}

.description {
  margin-bottom: 26px;
  margin-left: 9px;
  max-width: 334px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.37px;
  letter-spacing: -0.15px;
  color: $color-midnightblue;
}

.virtual-description {
  @extend .description;
  max-width: 345px;
}

.supervisor-description {
  @extend .description;
  max-width: 336px;
}

.kpi-box-wrapper {
  margin: 24px 0;
  padding: 7px 12px 10px;
  display: flex;
  justify-content: flex-start;
  background: $color-cornflowerblue;
  border-radius: 10px;

  .kpi-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: $color-white;
  }

  .kpi-box-title {
    margin-left: 2px;
    margin-bottom: 11px;
    font-size: 28px;
    line-height: 38.2px;
  }

  .kpi-box-description {
    margin-right: 27px;
    max-width: 100px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.37px;

    &.team {
      margin-right: 0;
    }
  }

  .kpi-box-divider {
    margin: 6px 16px 2px 10px;
    border-left: 1px solid $color-white;
    min-height: 100%;
  }
}

.supervisor-kpi-box-wrapper {
  @extend .kpi-box-wrapper;
  margin-bottom: 36px;
}

.item {
  $paddingX: 3px;

  padding-right: $paddingX;
  padding-left: $paddingX;
}

.action-item {
  button {
    white-space: inherit;
    text-align: left;
  }
}

.colored-col {
  color: $color-royalblue;

  span {
    color: $color-gray;
  }
}

.team-name {
  @extend .colored-col;
  width: 20%;
  font-size: 14px;
  line-height: 19.1px;
}

.team-col {
  @extend .colored-col;
  width: 10%;
  color: $color-royalblue;
}

.skill-report {
  &.name {
    width: 40%;
  }

  &.column {
    width: 16%;
  }

  &.text-left {
    div div {
      text-align: left;
    }
  }
}

.status,
.skill {
  width: 5%;
}

.name {
  width: 13.666666%;
}

.report {
  width: 9.666666%;
}

.thin {
  width: 6%;
}

.wide {
  width: 10.666666%;
}

.tab-wrapper {
  margin-top: 23px;

  > div {
    text-transform: uppercase;
  }
}

.virtual-tab-wrapper {
  @extend .tab-wrapper;
  margin-top: 28px;
  margin-bottom: 19px;
}

.report-filter {
  margin-bottom: 25px;
}

.supervisor-filter {
  margin-bottom: 42px;
}

.report-table-wrapper {
  padding: 0 10px;
}

.role {
  &-name {
    width: 23.5%;
  }

  &-other {
    width: 8.5%;
  }
}

.employee-overview {
  &-name {
    @extend .colored-col;
    width: 25%;
  }

  &-supervisor {
    @extend .colored-col;
    width: 15%;
  }

  &-area {
    @extend .colored-col;
    width: 20%;
  }

  &-exp,
  &-tenure {
    @extend .colored-col;
    width: 7%;
  }
}

.team-roster {
  &-name,
  &-title {
    @extend .colored-col;
    width: 20%;
  }

  &-exp {
    @extend .colored-col;
    width: 9%;
  }

  &-area {
    @extend .colored-col;
    width: 15%;
  }
}

.employee-roster {
  &-name,
  &-title {
    @extend .colored-col;
    width: 20%;
  }

  &-exp,
  &-tenure {
    @extend .colored-col;
    width: 9%;
  }

  &-geo,
  &-result {
    @extend .colored-col;
    width: 12%;
  }

  &-avg {
    @extend .colored-col;
    width: 6%;
  }
}

.team-supervisor-overview {
  color: $color-royalblue;

  span {
    color: $color-gray;
  }

  &.supervisor {
    width: 20%;
  }

  &.employee {
    width: 8%;
  }

  &.other-column {
    width: 12%;
  }
}

.tab-title {
  margin: 14px 0;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  color: $color-cornflowerblue;
}

.overview-tab-wrapper {
  margin: 19px 0;
  padding: 0 14px;
  border-bottom: 1px solid currentColor;
  color: $color-midnightblue;

  .overview-tab-item {
    padding-bottom: 11px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    &:not(:last-child) {
      margin-right: 32px;
    }

    &::after {
      height: 4px;
    }
  }
}
