@import "@styles/abstracts";

.rating-container {
  display: flex;
  align-items: center;
  grid-gap: 4px;

  .rating-stars {
  }

  .rating-number {
    color: $color-black;
    font-size: 10px;
  }
}
