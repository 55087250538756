@import "@styles/abstracts";

.column {
  border-right: 1px solid;
  border-color: $color-gainsboro;
}
.titleline {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: $color-gainsboro;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.title {
  margin-bottom: 0;
}
