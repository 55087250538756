@import "@styles/abstracts";

$paddingY: 5px;
$railHeight: 8px;
$trackBorderRadius: 6px;

.rc-slider {
  position: relative;
  height: $railHeight + $paddingY * 2;
  width: 100%;
  padding-top: $paddingY;
  padding-bottom: $paddingY;

  &-rail {
    $color: $color-gainsboro;

    position: absolute;
    z-index: 10;
    width: 100%;
    height: $railHeight;
    border-radius: $trackBorderRadius;
    cursor: pointer;
    background: $color;
    @include transition(background-color);

    .rc-slider:hover & {
      background-color: darken($color, 15%);
    }
  }

  &-track {
    position: absolute;
    z-index: 20;
    height: $railHeight;
    border-radius: $trackBorderRadius;
    background-color: $color-cornflowerblue;
  }

  &-handle {
    $size: 18px;
    $color: $main-color;

    top: 0;
    position: absolute;
    z-index: 30;
    width: $size;
    height: $size;
    cursor: pointer;
    touch-action: pan-x;
    border-radius: 50%;
    background-color: $main-color;
    @include transition(background-color);

    &:hover,
    &-dragging {
      background-color: darken($color, 15%);
    }
  }
}
