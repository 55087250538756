@import "@styles/abstracts";

.block {
  color: inherit;
}
.row {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
.label {
  width: 20%;
  font-weight: 800;
}
