@import "@styles/abstracts";

.status {
  font-weight: 600;
}
.activated {
  color: $color-success;
}
.active {
  color: $secondary-color;
}
.deleted {
  color: $color-pureedpumpkin;
}
