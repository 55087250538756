@import "@styles/abstracts";

.block {
  $marginX: auto;

  max-width: 518px;
  margin-right: $marginX;
  margin-left: $marginX;
}

.logo {
  margin-bottom: 20px;
}

.title {
  font-size: 22px;
  line-height: 30px;
  color: $color-midnightblue;
  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  line-height: 22px;
  font-weight: 800;
  color: $color-cornflowerblue;
}

.modal-row {
  padding: 0;
}

.tabs-panel {
  padding: 0;
}

.modal-body {
  padding: 0;
}
.image {
  max-width: 280px;
  max-height: 100px;
}
