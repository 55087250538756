@import "@styles/abstracts/";
@import "@styles/modules/grid.module.scss";

.table-wrapper {
  @include media-breakpoint-down(md) {
    overflow: auto;
  }
}

.table {
  table-layout: auto;
  width: 100%;
  color: $color-graphitegray;

  @include media-breakpoint-down(md) {
    width: max-content;
  }
}
.head {
  font-size: 12px;
  line-height: 16px;
  text-align: left;

  &-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    @include transition(color, font-weight);
    padding-bottom: 3px;

    &-sort-mode:hover,
    &-sorted {
      font-weight: 600;
    }
  }
  &-hide {
    display: none;
  }
}
.details-row {
  & > td {
    @extend .item-12x;
  }
}
.cell {
  & [class="ka-thead-cell-wrapper"],
  & [class="ka-thead-cell-content-wrapper"] {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  &-inner {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}
.filter {
  color: $color-graphitegray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.pagination {
  float: right;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;

  &-item {
    $paddingX: 5px;

    cursor: pointer;
    @include transition(color);
    padding-left: $paddingX;

    &:not(:last-child) {
      padding-right: $paddingX;
    }
  }
}
