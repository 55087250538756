@import url("~@fortawesome/fontawesome-free/css/all.min.css");
@import url("~react-datepicker/dist/react-datepicker.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .placeholder-font-normal::placeholder {
      font-weight: normal;
    }

    .placeholder-italic::placeholder {
      font-style: italic;
    }

    .after-hidden::after {
      display: none;
    }
  }
}

.react-calendar div div div button:enabled:hover {
  @apply bg-warning text-white;
}
