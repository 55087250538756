@import "@styles/abstracts";

.row {
  padding-right: $modalRowPaddingX;
  padding-left: $modalRowPaddingX;

  &-negative {
    margin-right: -$modalRowPaddingX;
    margin-left: -$modalRowPaddingX;
  }
}

.two-columns {
  display: flex;
  align-items: stretch;
  width: 100%;

  &-left {
    $marginX: 20px;

    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-width: 220px;
    margin-right: $marginX;
    padding-right: $marginX;

    &-button {
      width: 100%;
    }
  }
  &-right {
    flex-grow: 1;
  }
}
.separate-column {
  border-right: 1px solid $color-lighttelegrey;
}
