@import "@styles/abstracts";

$gridColor: $color-royalblue;

.block:not(:last-child) {
  margin-bottom: 40px;
}
.grid {
  font-size: 16px;
  line-height: 21px;
  color: $gridColor;
}
.title {
  font-size: 18px;
  line-height: 24px;
  border-bottom: 1px solid $color-lightgray;
  color: $color-royalblue;
  padding-bottom: 5px;
  margin-top: -10px;
  margin-bottom: 16px;
}
.description {
  margin-bottom: 7px;
}
.accountabilities {
  margin-top: 20px;
  margin-bottom: 22px;
}
.list:not(:last-child) {
  margin-bottom: 36px;
}
.total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 45px;

  &-text {
    color: $color-midnightblue;
    margin-right: 10px;
  }
  &-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 57px;
    height: 31px;
    border-radius: 2px;
    font-weight: 900;
    background-color: rgba($color-grassgreen, 0.3);
    color: $color-black;
  }
}
.feedback {
  &-row:not(:last-child) {
    margin-bottom: 20px;
  }
  &-date {
    font-weight: 800;
  }
  &-block {
    display: flex;
    flex-direction: column;
  }
  &-text {
    font-size: 14px;
    line-height: 19px;
  }
  &-titles {
    margin-bottom: 14px;
  }
  &-manager {
    font-style: italic;
  }
}
.nothing {
  color: $main-color;
}
