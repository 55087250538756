@import "@styles/abstracts";

.list {
  display: flex;
  align-items: stretch;
}
.tab {
  position: relative;
  cursor: pointer;
  opacity: 1;
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  text-transform: uppercase;
  @include transition(color, opacity);
  padding-bottom: 8px;

  &-disabled {
    cursor: not-allowed;
    opacity: 0.5;
    font-weight: 100;
    opacity: 0.2;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    height: 6px;
    pointer-events: none;
    background-color: transparent;
    @include transition(background-color);
  }

  &-selected {
    opacity: 1 !important;
  }

  &-selected:after,
  &:not(.tab-disabled):hover:after {
    z-index: 10;
    pointer-events: auto;
    background-color: currentColor;
  }

  &:not(:last-child) {
    margin-right: 25px;
  }

  &:not(.tab-disabled):hover {
    color: $secondary-color;
  }

  &-hidden {
    display: none;
  }
}
