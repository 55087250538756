.option {
  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 10px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.optionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
