@mixin fontFaceGenerator($family, $type, $weight: "normal", $italic: false) {
  $path: "";
  $code: "";

  @if $family == Nunito {
    $path: "/static/fonts/NunitoSans/";
    $code: "nunitosans";
  } @else if $family == Montserrat {
    $path: "/static/fonts/Montserrat/";
    $code: "montserrat";
  }

  @font-face {
    font-family: $family;
    src: url("#{$path}#{$code}-#{$type}.woff2") format("woff2"), url("#{$path}#{$code}-#{$type}.woff") format("woff");
    font-weight: $weight;
    font-style: normal;
  }
  @if ($italic) {
    @font-face {
      font-family: $family;
      src: url("#{$path}#{$code}-#{$type}-italic.woff2") format("woff2"),
        url("#{$path}#{$code}-#{$type}-italic.woff") format("woff");
      font-weight: $weight;
      font-style: italic;
    }
  }
}

@include fontFaceGenerator("Nunito", "regular", 400, true);
@include fontFaceGenerator("Nunito", "semibold", 600);
@include fontFaceGenerator("Nunito", "bold", 700);
@include fontFaceGenerator("Nunito", "black", 900);
@include fontFaceGenerator("Montserrat", "bold", 700);
@include fontFaceGenerator("Montserrat", "extra-bold", 800);
