@import "@styles/abstracts";

$skillNameWidth: 25%;
$skillNameMargin: 15px;
$listPaddingY: 5px;

.item {
  &:not(:last-child) {
    margin-bottom: 16px;

    & .list {
      padding-bottom: $listPaddingY;
    }
  }
  &-title {
    font-size: 18px;
    font-style: italic;
    line-height: 24px;
    margin-bottom: 10px;
  }
  &-row:not(:last-child) {
    margin-bottom: 5px;
  }
}
.progress {
  background-color: $color-silver;
}
.skills {
  &-done {
    color: $color-cornflowerblue;
  }
  &-fail {
    color: $main-color;
  }
}
.name {
  flex-shrink: 0;
  width: $skillNameWidth;
  font-size: 14px;
  line-height: 1.2;
  margin-right: $skillNameMargin;
}
.row {
  display: flex;
  align-items: center;

  &-weighted {
    $borderWidth: 1px;
    $paddingY: 7px;
    $paddingX: 0;

    border: solid;
    border-width: $borderWidth 0;
    border-color: $color-gainsboro;
    padding: $paddingY 0;
    margin-top: $paddingY;
    margin-right: $paddingX;
    margin-left: $paddingX;

    &:not(:last-child) {
      margin-bottom: $paddingY;
    }
  }
}
.list {
  padding-top: $listPaddingY;

  &-wrapper {
    position: relative;
  }
}
.target {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  &-value {
    font-size: 11px;
    line-height: 1.2;
    color: $color-darkslategray;
    white-space: nowrap;
    margin-top: -7px;
    margin-left: 10px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 15;
    background-color: $secondary-color;
  }

  &:before {
    bottom: 0;
    z-index: 10;
    width: 1px;
  }
  &:after {
    $size: 10px;

    width: $size;
    height: $size;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  &-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: calc(100% - #{$skillNameWidth} - #{$skillNameMargin});
  }
}
.switcher {
  margin-left: auto;

  &-wrapper {
    flex-grow: 1;
    display: flex;
    margin-left: 5px;
  }
  & [class*="item"] {
    width: 70px;
    font-size: 12px;
    line-height: 1.5;
    padding: 1px 5px;
  }
}
