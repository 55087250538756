@import "@styles/abstracts/";

// .head {
//   &-cell {
//     &-sort-mode:hover,
//     &-sorted {
//       color: $color-midnightblue;
//     }
//   }
// }
.cell {
  color: $color-midnightblue;
}
.row:not(.details-row) {
  background-color: $color-lavender-200;
}

// .pagination {
//   &-item {
//     color: $color-graphitegray;

//     &[class*="active"],
//     &:hover {
//       color: $secondary-color;
//     }
//   }
// }
