@import "@styles/abstracts";

.courseTileCardContainer {
  background: $color-whitesmoke;
  font: $font-nunito;

  .imageContainer {
    position: relative;

    .heartIcon {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .CourseTileCard {
    margin: 0;
  }

  .courseImg {
    height: 70px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  .gameTitleAndDescriptionContainer {
    padding: $grid-gap-small 0;

    .gameTitleAndDescription {
      height: 100%;
      border-right: 1px solid #cdcdcd;
      padding: 0 $grid-gap-small;

      .logoAndTitle {
        display: flex;
        align-items: center;
        grid-gap: 6px;

        .logo {
          max-width: 16px;
          flex-shrink: 0;
        }
      }
    }
  }

  .ratingContainer {
    padding: $grid-gap-small 0;

    .rating {
      padding: 0 $grid-gap-small;
      display: grid;
      place-items: center;
      height: 100%;
      border-right: 1px solid #cdcdcd;
    }
  }

  .durationContainer {
    padding: $grid-gap-small 0;

    .duration {
      padding: 0 $grid-gap-small;
      height: 100%;
      border-right: 1px solid #cdcdcd;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 6px;

      .durationIcon {
        max-width: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .durationTime {
        font-size: 10px;
      }
    }
  }

  .levelAndPriceContainer {
    padding: $grid-gap-small 0;

    .levelAndPrice {
      padding: 0 $grid-gap-small;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .level {
        font-size: 14px;
        font-weight: bold;
      }

      .ctaBtn {
        width: auto;
      }
    }
  }

  .description {
    font-size: 10px;
    color: $color-black;
  }

  .btnContainer {
    width: 100%;
    display: flex;
    justify-content: end;
    column-gap: 10px;
    align-items: center;
  }
}
.progressbar {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 5px;
  background-color: $color-white;

  &Filler {
    position: absolute;
    height: 100%;
    background-color: $color-red;
  }
}
